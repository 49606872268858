import cloneDeep from "lodash/cloneDeep";
import {
  adCfStatusMapper,
  agentListOfListingsInfoInitial,
  listingTabsNamesMapper,
  notApplicable,
} from "../../../constants/constants";
import {
  numberWithCommas,
  formatAddressString,
  valueOrNaConverter,
} from "../../../helpers/formatters";

// to do need refactor function
const getAllListingsTableInfo = ({ data, tab, search }) => {
  const filteredData = [];
  const info = cloneDeep(agentListOfListingsInfoInitial);
  info.filterTabs[listingTabsNamesMapper.all].value = data.length;

  const totalFormattingConfig = {
    price: {
      hasValue: true,
      format: (value) => `$${numberWithCommas(value)}`,
    },
    adSpend: {
      hasValue: true,
      format: (value) => `$${numberWithCommas(Number(value.toFixed(2)))}`,
    },
    adReach: {
      hasValue: false,
      format: (value) => valueOrNaConverter(value),
    },
    adStatus: {
      hasValue: true,
      format: () => "",
    },
    totalViews: {
      hasValue: false,
      format: (value) => valueOrNaConverter(value),
    },
    totalLeads: {
      hasValue: false,
      format: (value) => valueOrNaConverter(value),
    },
  };

  data.forEach((item) => {
    const {
      adStatus,
      price,
      adSpend,
      adReach,
      totalViews,
      totalLeads,
      displayAddress,
    } = item;

    const { filterIndex } = adCfStatusMapper[adStatus];
    if (filterIndex) info.filterTabs[filterIndex].value += 1;
    if (
      (tab === listingTabsNamesMapper.all || tab === filterIndex) &&
      (displayAddress?.toLowerCase().includes(search.toLowerCase()) ||
        search === "")
    ) {
      filteredData.push({
        ...item,
        displayAddress: formatAddressString(displayAddress),
      });

      info.total.price += price;
      info.total.adSpend += adSpend;

      if (adReach >= 0) {
        info.total.adReach += adReach;
        totalFormattingConfig.adReach.hasValue = true;
      }
      if (totalViews >= 0) {
        info.total.totalViews += totalViews;
        totalFormattingConfig.totalViews.hasValue = true;
      }
      if (totalLeads >= 0) {
        info.total.totalLeads += totalLeads;
        totalFormattingConfig.totalLeads.hasValue = true;
      }
    }
  });

  Object.entries(info.total).forEach(([key, value]) => {
    info.total[key] = totalFormattingConfig[key].hasValue
      ? totalFormattingConfig[key].format(value)
      : notApplicable;
  });
  return { data, filteredData, info };
};

export default getAllListingsTableInfo;
