import React from "react";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import {
  applyChooseImagesAction,
  setHousePhotoAction,
} from "../../../store/actions/ad/adActions";
import { setPromoteListingStepAction } from "../../../store/actions/listing/listingActions";
import {
  selectAdMediaType,
  selectAdGoal,
  selectAdFbHousePhotosSingle,
  selectAdGoogleHousePhotosSingle,
  selectAdHousePhotosCarousel,
  selectAdType,
} from "../../../store/selectors/adSelector";
import { selectPromoteListingStep } from "../../../store/selectors/listingSelector";
import {
  adTypes,
  adPhotosMinCount,
  adPhotosMaxCount,
  adLeadPhotoMaxCount,
} from "../../../constants/constants";
import { getMaxPhotoCount } from "../../../helpers/functions";
import StepsCountColumn from "../promoteLayout/StepsCountColumn";
import Tooltip from "../../shared/Tooltip";
import { adMediaTypesEnum, goalsEnum } from "../../../constants/enums";

const ImagesBottomList = () => {
  const dispatch = useDispatch();

  const adMediaType = useSelector(selectAdMediaType);
  const adHouseFbPhotosSingle = useSelector(selectAdFbHousePhotosSingle);
  const adHouseGooglePhotosSingle = useSelector(
    selectAdGoogleHousePhotosSingle
  );
  const adHousePhotosCarousel = useSelector(selectAdHousePhotosCarousel);
  const adType = useSelector(selectAdType);
  const promoteListingStep = useSelector(selectPromoteListingStep);
  const adGoal = useSelector(selectAdGoal);
  const photosMaxCount = getMaxPhotoCount(adGoal);
  const isCarouselTabActive = adType === adTypes.CAROUSEL;
  const houseSinglePhotos =
    adMediaType === adMediaTypesEnum.Facebook
      ? adHouseFbPhotosSingle
      : adHouseGooglePhotosSingle;
  const adHousePhotos = isCarouselTabActive
    ? adHousePhotosCarousel
    : houseSinglePhotos;
  const adHousePhotosCount = get(adHousePhotos, "length", 0);
  const enabledChooseButton = {
    [adTypes.SINGLE]: adHousePhotosCount === 1,
    [adTypes.CAROUSEL]: adHousePhotosCount >= adPhotosMinCount,
  }[adType];

  const goBack = () =>
    dispatch(setPromoteListingStepAction(promoteListingStep - 1));

  const imageListRenderer = () => {
    const imagesList = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < photosMaxCount; i++) {
      imagesList.push(
        <div
          key={`bottom_${i}`}
          className={`choose-listing-head-images-column ${
            !isCarouselTabActive && i >= 1 ? "empty" : ""
          }`}
        >
          {adHousePhotos[i] ? (
            <img
              src={adHousePhotos[i]}
              alt="House Image"
              onClick={() => dispatch(setHousePhotoAction(adHousePhotos[i]))}
              className="choose-listing-head-img"
            />
          ) : (
            <div className="choose-listing-head-no-images">
              <span>No image</span>
            </div>
          )}
        </div>
      );
    }

    return imagesList;
  };

  return (
    <div className={`steps-bottom ${isCarouselTabActive ? "" : "active"}`}>
      <div className="next-step-row">
        <div className="container">
          <div className="next-step-row-inner">
            <div className="next-step-left-column">
              <div className="choose-listing-selected-images">
                <div className="choose-listing-selected-images-row">
                  <div className="choose-listing-s-i-column-left">
                    <div className="choose-listing-head-column-left-content">
                      <div className="choose-listing-head-choose-column">
                        <button
                          onClick={goBack}
                          className={"button back-button"}
                        >
                          Go Back
                        </button>
                        <button
                          className={`button ${
                            enabledChooseButton ? "" : "disabled"
                          }`}
                          onClick={() => dispatch(applyChooseImagesAction())}
                        >
                          choose
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="choose-listing-s-i-column-right">
                    <div className="choose-listing-head-images">
                      {imageListRenderer()}
                    </div>
                  </div>
                </div>
                <div className="choose-listing-head-texts">
                  <span className="choose-listing-head-info">
                    {adGoal === goalsEnum.LEAD_GENERATION ? (
                      <>
                        {" "}
                        You can select
                        <span>
                          {adPhotosMinCount} - {adLeadPhotoMaxCount}
                        </span>
                      </>
                    ) : (
                      <>
                        You can select maximum
                        <span>{adPhotosMaxCount}</span>
                      </>
                    )}
                    images; deselect an image to remove it.
                  </span>
                  <Tooltip>
                    <p>Industry recommends no fewer than 3 images.</p>
                  </Tooltip>
                </div>
              </div>
            </div>
            <StepsCountColumn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesBottomList;
