import React from "react";
import PropTypes from "prop-types";
import { displayCssTypeMapper } from "../../constants/constants";

const AdPopup = ({ children, popupState, setPopupState }) => (
  <div className="preview-your-ad-popup" style={{ display: popupState }}>
    <button
      onClick={() => setPopupState(displayCssTypeMapper.none)}
      className="preview-your-ad-popup-close"
    >
      <i className="icon-close" />
    </button>
    <div className="preview-your-ad-popup-card">
      {popupState === displayCssTypeMapper.flex ? children : null}
    </div>
  </div>
);

AdPopup.propTypes = {
  children: PropTypes.object,
  popupState: PropTypes.oneOf(Object.values(displayCssTypeMapper)),
  setPopupState: PropTypes.func,
};

export default AdPopup;
