import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Facebook from "../../../assets/images/fb.svg";
import Instagram from "../../../assets/images/instagram.svg";
import Google from "../../../assets/images/google.svg";
import {
  adMediaTypesEnum,
  goalsEnum,
  promoteStepsEnum,
} from "../../../constants/enums";
import { promoteGoalText } from "../../../constants/constants";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { setPromoteListingStepAction } from "../../../store/actions/listing/listingActions";

const SocialMediaPopup = ({ menuType, closePopup, goal }) => {
    const dispatch = useDispatch();
    const menuRef = useRef(null);
    useOutsideClick(menuRef, closePopup);
    const isOpen = !menuType
        || (menuType === adMediaTypesEnum.Google && goal === goalsEnum.LEAD_GENERATION)
        ? ""
        : "open";

    return (
        <div
            ref={menuRef}
            className={`social-popup ${isOpen}`}
        >
            <div className="social-popup-head">
                <button onClick={closePopup} className="social-popup-close">
                    <i className="icon-close"/>
                </button>
            </div>
            <div className="social-popup-content">
                {menuType === adMediaTypesEnum.Facebook ?
                    <>
                        <div className="social-popup-c-images-row">
                            <div className="social-popup-c-images-row-inner">
                                <div className="social-popup-c-i-column">
                                    <img
                                        src={Facebook}
                                        alt="facebook"
                                        className="social-popup-img"
                                    />
                                </div>
                                <div className="social-popup-c-i-column">
                                    <i className="icon-plus social-popup-plus"/>
                                </div>
                                <div className="social-popup-c-i-column">
                                    <img
                                        src={Instagram}
                                        alt="instagram"
                                        className="social-popup-img"
                                    />
                                </div>
                            </div>
                            <h3 className="social-popup-media-name">facebook +</h3>
                            <h3 className="social-popup-media-name"> instagram ad</h3>
                        </div>
                        <p className="social-popup-info">
                            {
                                goal === goalsEnum.LINK_CLICKS
                                ? promoteGoalText.clicks
                                : promoteGoalText.leads
                            }
                        </p>
                    </> : null
                }
                {menuType === adMediaTypesEnum.Google ?
                    <>
                        <div className="social-popup-c-images-row">
                            <div className="social-popup-c-images-row-inner">
                                <div className="social-popup-c-i-column">
                                    <img
                                        src={Google}
                                        alt="google"
                                        className="social-popup-img"
                                    />
                                </div>
                            </div>
                            <h3 className="social-popup-media-name">google ad</h3>
                        </div>
                        <p className="social-popup-info">
                            {
                                "This ad targets people in-market for residential real estate, purchasing a home/moving, and avid investors in the NYC DMA. Network of websites may include nytimes.com, wsj.com, forbes.com, bloomberg.com, washingtonpost.com, newyorker.com, timeout.com/newyork, nymag.com, marketwatch.com, ft.com, and others."
                            }
                        </p>
                    </> : null
                }
            </div>
            <div className="social-popup-bottom">
                <button
                    className="button"
                    onClick={() =>
                        dispatch(setPromoteListingStepAction(promoteStepsEnum.ReviewCopy))}
                >
                    Next Step
                </button>
            </div>
        </div>
    );
};

SocialMediaPopup.propTypes = {
    menuType: PropTypes.oneOf(Object.values(adMediaTypesEnum)),
    closePopup: PropTypes.func.isRequired,
    goal: PropTypes.oneOf(Object.values(goalsEnum)).isRequired,
};

export default SocialMediaPopup;
