import React from "react";
import { Portal } from "react-portal";
import { useSelector } from "react-redux";
import { selectConvertedHousePhotos } from "../../../store/selectors/adSelector";
import AdSimpleImage from "../../../UI/AdSimpleImage";

const PortalCarouselImages = ({ setConvertedRefs }) => {
  const convertedHousePhotos = useSelector(selectConvertedHousePhotos);

  const setRef = (id) => (element) => {
    if (element) {
      setConvertedRefs((refs) => {
        const isHave = refs.find((item) => item.id === id);
        if (!isHave) {
          return [...refs, { id, element }];
        } else {
          return refs;
        }
      });
    }
  };

  if (convertedHousePhotos.length < 2) {
    return null;
  }

  return (
    <>
      {convertedHousePhotos.map((item, index) => (
        <Portal key={item.id}>
          {index !== 0 ? (
            <AdSimpleImage photoUrl={item.data} setRef={setRef(item.id)} />
          ) : null}
        </Portal>
      ))}
    </>
  );
};

export default PortalCarouselImages;
