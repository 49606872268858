import React from "react";
import { useSelector } from "react-redux";
import {
  selectAdMediaType,
  selectAdGoal,
} from "../../../store/selectors/adSelector";
import { adMediaTypesEnum, goalsEnum } from "../../../constants/enums";
import ReviewCopyForm from "./ReviewCopyForm";
import { selectStepLoading } from "../../../store/selectors/listingSelector";

const ReviewCopyView = () => {
  const adMediaType = useSelector(selectAdMediaType);
  const adGoal = useSelector(selectAdGoal);
  const stepLoading = useSelector(selectStepLoading);

  const facebookFormVisible = adMediaType === adMediaTypesEnum.Facebook;
  const googleFormVisible = adMediaType === adMediaTypesEnum.Google;
  const callToActionFormVisible =
    facebookFormVisible && adGoal === goalsEnum.LINK_CLICKS;

  return (
    <div className="review-copy-step step-cnt">
      <div className="container">
        {stepLoading ? (
          <div className="loader" />
        ) : (
          <div className="review-copy-inputs-row">
            {facebookFormVisible ? (
              <ReviewCopyForm
                mediaType={adMediaTypesEnum.Facebook}
                callToActionFormVisible={callToActionFormVisible}
              />
            ) : null}
            {googleFormVisible ? (
              <ReviewCopyForm mediaType={adMediaTypesEnum.Google} />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewCopyView;
