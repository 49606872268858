import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Facebook from "../../../assets/images/fb.svg";
import Instagram from "../../../assets/images/instagram.svg";
import InstagramGs from "../../../assets/images/instagram-gs.svg";
import FacebookGs from "../../../assets/images/facebook-gs.svg";
import SocialMediaPopup from "./SocialMediaPopup";
import { adMediaTypesEnum } from "../../../constants/enums";
import { setAdMediaTypesAction } from "../../../store/actions/ad/adActions";
import {
  selectAdMediaType,
  selectAdGoal,
} from "../../../store/selectors/adSelector";

const ChooseYourMediaStep = () => {
  const adMediaType = useSelector(selectAdMediaType);
  const [menuMediaType, setMenuMediaType] = useState(adMediaType);

  const goal = useSelector(selectAdGoal);

  const dispatch = useDispatch();

  const isSelectedFacebook = adMediaType === adMediaTypesEnum.Facebook;
  const isSelectedGoogle = adMediaType === adMediaTypesEnum.Google;

  const onSelectAdMediaType = (type) => {
    let mediaType;

    if (type === adMediaTypesEnum.Facebook) {
      if (!isSelectedFacebook) {
        setMenuMediaType(adMediaTypesEnum.Facebook);
        mediaType = adMediaTypesEnum.Facebook;
      }
    }

    if (type === adMediaTypesEnum.Google) {
      if (!isSelectedGoogle) {
        setMenuMediaType(adMediaTypesEnum.Google);
        mediaType = adMediaTypesEnum.Google;
      }
    }

    dispatch(setAdMediaTypesAction(mediaType));
  };

  return (
    <div className="choose-your-media-step step-cnt">
      <div className="container">
        <p className="choose-your-media-step-title">
          Please choose a media platform or platforms to promote your listing.
          Your paid media ad will direct to the listing page on Elliman.com.
        </p>
        <div className="social-sites-row">
          <div
            onClick={() => onSelectAdMediaType(adMediaTypesEnum.Facebook)}
            className={`social-site-column ${
              isSelectedFacebook ? "active" : ""
            }`}
          >
            <div className="social-site-c-inner">
              <div className="social-site-c-inner-column">
                <div className="social-site-images">
                  <img
                    src={FacebookGs}
                    alt="facebook"
                    className="social-site-img"
                  />
                  <img
                    src={Facebook}
                    alt="facebook"
                    className="social-site-img"
                  />
                </div>
                <h3 className="social-site-name">facebook</h3>
              </div>
              <div className="social-site-c-inner-column plus">
                <i className="icon-plus social-site-c-plus" />
              </div>
              <div className="social-site-c-inner-column">
                <div className="social-site-images">
                  <img
                    src={InstagramGs}
                    alt="instagram"
                    className="social-site-img"
                  />
                  <img
                    src={Instagram}
                    alt="instagram"
                    className="social-site-img"
                  />
                </div>
                <h3 className="social-site-name">instagram</h3>
              </div>
            </div>
            <button className="social-site-choose">
              {isSelectedFacebook ? "chosen" : "choose"}
            </button>
          </div>
          {/* {goal === goalsEnum.LINK_CLICKS ? (
            <div
              onClick={() => onSelectAdMediaType(adMediaTypesEnum.Google)}
              className={`social-site-column ${
                isSelectedGoogle ? "active" : ""
              }`}
            >
              <div className="social-site-c-inner">
                <div className="social-site-c-inner-column">
                  <div className="social-site-images">
                    <img
                      src={GoogleGs}
                      alt="google"
                      className="social-site-img"
                    />
                    <img
                      src={Google}
                      alt="google"
                      className="social-site-img"
                    />
                  </div>
                  <h3 className="social-site-name">google</h3>
                </div>
              </div>
              <button className="social-site-choose">
                {isSelectedGoogle ? "chosen" : "choose"}
              </button>
            </div>
          ) : null} */}
        </div>
      </div>
      <SocialMediaPopup
        goal={goal}
        menuType={menuMediaType}
        closePopup={() => setMenuMediaType(null)}
      />
    </div>
  );
};

export default ChooseYourMediaStep;
