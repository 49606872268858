import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { noop } from "lodash";
import { getAssistedForAction } from "../../store/actions/delegation/delegationAction";
import {
  selectAssistedFor,
  selectAssistedForLoading,
} from "../../store/selectors/delegationSelector";
import DelegateSingleUser from "../../UI/DelegateSingleUser";
import { changeDelegation } from "../../store/actions/user/userAction";

const USER_ACTIVE_STATUS = "Active";

const SharedUsers = ({ onClick, isOpen, onClose = noop }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const assistedFor = useSelector(selectAssistedFor);
  const assistedForLoading = useSelector(selectAssistedForLoading);

  useEffect(() => {
    if (!assistedFor.length) {
      dispatch(getAssistedForAction());
    }
  }, []);

  const hasSharedUser = assistedForLoading || !!assistedFor.length;

  const handleSelect = (user) => () => {
    if (user?.status === USER_ACTIVE_STATUS) {
      const userId = user?.userId;
      dispatch(changeDelegation(userId, history));
      onClose();
    }
  };

  return (
    <div className={`shared-users ${isOpen ? "open" : ""}`}>
      <div className="shared-users-top">
        <h3 onClick={onClick} className="box-title">
          <i className="icon-arrow-left-b"></i>Shared With You
        </h3>
      </div>
      {hasSharedUser ? (
        <>
          <div className="shared-users-body">
            {assistedFor.map((user) => (
              <DelegateSingleUser
                firstName={user.firstName}
                lastName={user.lastName}
                active={user.status === USER_ACTIVE_STATUS}
                onClick={handleSelect(user)}
                key={user.userId}
                avatarUrl={user.avatarUrl}
                userName={user.fullName}
                userEmail={user.email}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="empty-user-text">
          <p>No one has shared their access with you.</p>
        </div>
      )}
    </div>
  );
};

export default SharedUsers;
