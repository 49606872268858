import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import {
  selectAdCallToAction,
  selectAdGoal,
  selectAdLogoType,
  selectAdPreviewInfo,
  selectAdType,
  selectConvertedHousePhotos,
  selectFacebookHeadline,
  selectFacebookPrimaryText,
  selectAdMediaType,
} from "../../../store/selectors/adSelector";
import {
  selectAgentDesignation,
  selectAgentTeamInfo,
  selectListingFbInfo,
} from "../../../store/selectors/listingSelector";
import { selectBrand } from "../../../store/selectors/brandSelector";

import { callToActionTypes, adTypes } from "../../../constants/constants";
import {
  creativeLogoTypesEnum,
  goalsEnum,
  SocialEnum,
  adMediaTypesEnum,
  previewTypeEnum,
} from "../../../constants/enums";
import {
  getAdPreviewInfo,
  emptyAdPreviewInfo,
} from "../../../store/actions/ad/adActions";

import Logo from "../../../assets/images/de.svg";
import FacebookPreview from "./FacebookPreview";
import InstagramPreview from "./InstagramPreview";
import InstagramStorePreview from "./InstagramStorePreview";
import GooglePreview from "./GooglePreview";
import { capitalizeFirstLetter } from "../../../helpers/formatters";
import { selectCurrentUser } from "../../../store/selectors/userSelector";
import AdGeneratePreview from "../../../UI/AdGeneratePreview";
// eslint-disable-next-line import/named

const getIsLead = (adGoal, previewGoal) => {
  if (previewGoal) {
    return goalsEnum[previewGoal] === goalsEnum.LEAD_GENERATION;
  } else {
    return adGoal === goalsEnum.LEAD_GENERATION;
  }
};

const AdView = ({
  openPopup,
  adId = "",
  mainImageRef = null,
  activeSocial = SocialEnum.FACEBOOK,
  disableCarouselSwipe = false,
  isPopup = false,
  userId,
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const adMediaType = useSelector(selectAdMediaType);
  const convertedHousePhotos = useSelector(selectConvertedHousePhotos);
  const adCall = useSelector(selectAdCallToAction);
  const facebookHeadline = useSelector(selectFacebookHeadline);
  const facebookPrimaryText = useSelector(selectFacebookPrimaryText);
  const adLogoType = useSelector(selectAdLogoType);
  const agentDesignation = useSelector(selectAgentDesignation);
  const adGoal = useSelector(selectAdGoal);
  const listingFbInfo = useSelector(selectListingFbInfo);
  const teamInfo = useSelector(selectAgentTeamInfo);
  const brand = useSelector(selectBrand);
  const { data, loading } = useSelector(selectAdPreviewInfo);
  const adType = useSelector(selectAdType);

  const pageName =
    activeSocial === SocialEnum.FACEBOOK
      ? get(data, "fbPageInfo.fbPageName", "")
      : get(data, "fbPageInfo.instagramAccountName", "");
  const listingInfoName =
    activeSocial === SocialEnum.FACEBOOK
      ? get(listingFbInfo, "fbPageName", "")
      : get(listingFbInfo, "instagramAccountName", "");

  const isSingleImage = isPopup
    ? data?.photos?.length === 1
    : adType === adTypes.SINGLE;

  const dispatch = useDispatch();

  const isAgentViewMode = adLogoType === creativeLogoTypesEnum.AgentLogo;
  const isTeamViewMode = adLogoType === creativeLogoTypesEnum.CompanyLogo;
  const agencyLogo = data?.agencyLogo || currentUser?.previewLogoUrl || "";
  const agencyName =
    pageName || listingInfoName || data?.agencyName || brand?.name || "";
  const primaryText = data?.primaryText || facebookPrimaryText || "";
  const photos = data?.photos || convertedHousePhotos || [];
  const headline = data?.headline || facebookHeadline || "";
  const adCallValue = adCall
    ? callToActionTypes[adCall - 1]
    : callToActionTypes[1];
  const callToAction = data?.callToAction
    ? capitalizeFirstLetter(data.callToAction?.replaceAll("_", " "))
    : adCallValue;
  const websiteUrl = brand?.websiteUrl || "";
  const isLead = getIsLead(adGoal, data?.goal);
  const isMobile = window.innerWidth < 758;
  const caption = data?.caption || isLead ? "FORM ON FACEBOOK" : websiteUrl;

  let adLogo,
    adTitle,
    adDesignation = "";

  if (isAgentViewMode) {
    adLogo = currentUser?.avatarUrl;
    adTitle = `${currentUser?.firstName} ${currentUser?.lastName}`;
    adDesignation = agentDesignation;
  }

  if (isTeamViewMode) {
    adLogo = teamInfo?.teamLightLogo || Logo;
    adTitle = teamInfo?.teamName;
    adDesignation = currentUser?.teamDesignation;
  }

  useEffect(() => {
    if (adId) {
      dispatch(getAdPreviewInfo(adId, userId));
      return () => emptyAdPreviewInfo();
    }
  }, []);

  const renderAdView = () => {
    const props = {
      agencyLogo,
      agencyName,
      adTitle,
      isAgentViewMode,
      adId,
      adDesignation,
      isTeamViewMode,
      adLogo,
      photos,
      openPopup,
      callToAction,
      headline,
      caption,
      primaryText,
      disableCarouselSwipe,
      isSingleImage,
    };

    const getIsActive = (type) => {
      if (isPopup) return true;
      if (!isMobile) return activeSocial === type;
      return true;
    };

    return (
      <>
        {adMediaType === adMediaTypesEnum.Facebook || !adMediaType ? (
          <>
            <FacebookPreview
              {...props}
              isActive={getIsActive(SocialEnum.FACEBOOK)}
            />
            <InstagramPreview
              {...props}
              isActive={getIsActive(SocialEnum.INSTAGRAM)}
            />
            <InstagramStorePreview
              {...props}
              isActive={getIsActive(SocialEnum.STORIES)}
            />
          </>
        ) : (
          <>
            <GooglePreview
              activeSocial={activeSocial}
              openPopup={openPopup}
              isActive={getIsActive(previewTypeEnum.VERTICAL)}
              sliderViewType={previewTypeEnum.VERTICAL}
            />
            <GooglePreview
              activeSocial={activeSocial}
              openPopup={openPopup}
              isActive={getIsActive(previewTypeEnum.SQUARE)}
              sliderViewType={previewTypeEnum.SQUARE}
            />
          </>
        )}
      </>
    );
  };

  return loading ? (
    <div className="loader" data-html2canvas-ignore />
  ) : (
    <>
      {renderAdView()}
      <AdGeneratePreview
        generatePreviewRef={mainImageRef}
        isTeamViewMode={isTeamViewMode}
        adDesignation={adDesignation}
        adTitle={adTitle}
        adLogo={adLogo}
        imageUrl={photos?.[0]?.data}
      />
      {/* // TO DO need delete in refactor time,  now need  for a delete all
      styles with classes in this part html */}
      {/* <div
            ref={mainImageRef}
            id="mainImage"
            className="preview-your-ad-card-inner"
          >
            {!adId ? (
              <div className="preview-your-ad-card-head">
                {isAgentViewMode ? (
                  <div className="preview-your-ad-card-head-column">
                    <h3 className="preview-your-ad-card-user">{adTitle}</h3>
                    <p className="preview-your-ad-card-user-info">
                      {adDesignation}
                    </p>
                  </div>
                ) : null}
                <LogoMaker src={adLogo} isTeamViewMode={isTeamViewMode} />
              </div>
            ) : null}
            <div className="preview-your-ad-card-content">
              <img
                src={photos?.[0]?.data}
                alt="House Photo"
                className="preview-your-ad-card-img"
              />
            </div>
          </div> */}
      {/* </div> */}
      {/* <GoogleAdPdf/> */}
    </>
  );
};

AdView.propTypes = {
  openPopup: PropTypes.func,
  adId: PropTypes.string,
  mainImageRef: PropTypes.object,
};

export default AdView;
