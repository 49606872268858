import adActionTypes from "./adActionTypes";
import axios from "../../../api/index";
import { getStore } from "../../index";
import {
  selectAd,
  selectAdFbHousePhotosSingle,
  selectAdGoogleHousePhotosSingle,
  selectAdHousePhotosCarousel,
  selectAdType,
  selectAdConfigsByGoal,
  selectAdGoal,
} from "../../selectors/adSelector";
import {
  adTypes,
  invoiceTypes,
  networkActions,
} from "../../../constants/constants";
import {
  prepareDeviceChartData,
  prepareMapData,
} from "../../../helpers/charts";
// eslint-disable-next-line import/no-cycle
import {
  getFairHousingCheckAction,
  setPaymentListingStepAction,
  setPromoteListingStepAction,
  setStepLoadingAction,
} from "../listing/listingActions";
import { getPromotedAdsAction } from "../insight/insightActions";
import {
  selectCreditBillingZipCode,
  selectCreditCardNumber,
  selectCreditExpirationDate,
  selectCreditFullName,
  selectIsAmpAvailable,
  selectIsCreditCardAvailable,
  selectPaymentType,
} from "../../selectors/paymentSelector";
import {
  adMediaTypesEnum,
  callToActionEnum,
  goalsEnum,
  paymentSepsEnum,
  paymentTypesEnum,
  promoteStepsEnum,
} from "../../../constants/enums";
import { notificationsServices } from "../../../helpers/notifications";
import {
  formatAddressString,
  getAdConfigByGoal,
} from "../../../helpers/formatters";
import { saveMarketingBudgetsAction } from "../payment/paymentActions";
import { getMaxPhotoCount } from "../../../helpers/functions";

export const saveAdNotificationAction = (message) => (dispatch) => {
  dispatch({
    type: adActionTypes.SAVE_AD_NOTIFICATION,
    payload: message,
  });
};

export const setInvoiceInfoAction = (invoice) => (dispatch) => {
  dispatch({
    type: adActionTypes.SAVE_INVOICE_INFO,
    payload: invoice,
  });
};

export const saveAdLoadingAction = (loading) => (dispatch) => {
  dispatch({
    type: adActionTypes.SAVE_AD_LOADING,
    payload: loading,
  });
};

export const publishAdAction = () => async (dispatch) => {
  try {
    dispatch(saveAdLoadingAction(true));

    const store = getStore();

    const ad = selectAd(store);
    const paymentType = selectPaymentType(store);
    const isAmpAvailable = selectIsAmpAvailable(store);
    const isCreditCardAvailable = selectIsCreditCardAvailable(store);
    const isPaymentMethod = isAmpAvailable || isCreditCardAvailable;

    const requestBody = {
      createAd: {},
    };

    if (ad.adMediaType === adMediaTypesEnum.Facebook) {
      const firstPhoto = ad.adType === adTypes.SINGLE ?
      selectAdFbHousePhotosSingle(store)[0] : selectAdHousePhotosCarousel(store)[0];
      const photos = ad.generatedPhotos.map((item) => item.split("base64,")[1]);

      const callAction =
        ad.goal === goalsEnum.LEAD_GENERATION
          ? callToActionEnum.LearnMore
          : ad.callToAction;

      requestBody.createAd.adSpecs = [
        {
          socialPlatform: adMediaTypesEnum.Facebook,
          headline: ad.facebookHeadline,
          primaryText: ad.facebookPrimaryText,
          callToActionButton: callAction,
        },
      ];

      requestBody.createAd.creativeLogoType = ad.adLogoType;
      requestBody.createAd.photos = photos;
      requestBody.createAd.firstPhoto = firstPhoto;
      requestBody.createAd.budgetId = ad.budgetId;
      requestBody.createAd.listingId = ad.listingId;
      requestBody.createAd.goal = ad.goal;
      requestBody.createAd.adType = ad.adType;

      requestBody.paymentType = isPaymentMethod ? paymentType : null;

      if (paymentType === paymentTypesEnum.CreditCard && isPaymentMethod) {
        const fullName = selectCreditFullName(store);
        const cardNumber = selectCreditCardNumber(store);
        const expirationDate = selectCreditExpirationDate(store);
        const billingZipCode = selectCreditBillingZipCode(store);

        requestBody.payWithCreditCard = {
          billingFirstName: fullName.split(" ")[0],
          billingLastName: fullName.split(" ")[1],
          cardNumber: cardNumber.replaceAll(" ", ""),
          expirationDate: expirationDate.replace("/", ""),
          billingZipCode,
        };
      }
    } else {
      return; // google case
    }

    const response = await axios.post(networkActions.PUBLISH_AD, requestBody);

    if (response?.data?.isSuccess || response?.data?.isAMPPaymentPending) {
      const type = response?.data?.isAMPPaymentPending
        ? invoiceTypes.PENDING
        : invoiceTypes.APPROVED;

      dispatch(
        setInvoiceInfoAction({
          type,
          info: { ...response?.data?.invoiceInfo, adId: response?.data?.adId },
        })
      );

      dispatch(setPaymentListingStepAction(paymentSepsEnum.Confirmation));
      return;
    }

    notificationsServices.error(response?.data?.additionalMessage);
    dispatch(saveAdLoadingAction(false));
  } catch (error) {
    dispatch(saveAdLoadingAction(false));
    notificationsServices.error(error);
  }
};

export const setCancelAdLoadingAction = (loading) => (dispatch) => {
  dispatch({
    type: adActionTypes.CANCEL_AD_LOADING,
    payload: loading,
  });
};

export const setCancelAdSuccessAction = (success) => (dispatch) => {
  dispatch({
    type: adActionTypes.SUCCESSFULLY_CANCEL_AD,
    payload: success,
  });
};

export const setCancelAdErrorAction = (error) => (dispatch) => {
  dispatch({
    type: adActionTypes.CANCEL_AD_ERROR,
    payload: error,
  });
};

export const cancelAdAction =
  (listingId, internalAdId, callback) => async (dispatch) => {
    try {
      dispatch(setCancelAdLoadingAction(true));

      const cancelAdResponse = await axios.patch(
        `${networkActions.CANCEL_AD}?internalAdId=${internalAdId}`
      );

      dispatch(getPromotedAdsAction(listingId));
      if (cancelAdResponse?.data?.successful) {
        return dispatch(
          setCancelAdSuccessAction(cancelAdResponse.data.successful)
        );
      }

      callback();
      dispatch(setCancelAdLoadingAction(false));
      dispatch(setCancelAdErrorAction(cancelAdResponse?.data?.message));
    } catch (error) {
      dispatch(setCancelAdLoadingAction(false));
      notificationsServices.error(error);
      callback();
    }
  };

export const setAdListingIdAction = (listingId) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_LISTING_ID,
    payload: listingId,
  });
};

export const setAdConfigsByGoalAction = (payload) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_CONFIGS_BY_GOAL,
    payload,
  });
};

export const setAdCallAction = (callAction) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_CALL_ACTION,
    payload: callAction,
  });
};

export const setAdTypeAction = (tab) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_TYPE,
    payload: tab,
  });
};

export const setAdMediaTypesAction = (adMediaType) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_MEDIA_TYPES,
    payload: adMediaType,
  });
};

export const setAdGoalAction = (goal) => (dispatch) => {
  const store = getStore();
  const adConfigsByGoal = selectAdConfigsByGoal(store);
  const adConfig = getAdConfigByGoal(adConfigsByGoal[goal]);

  if (goal === goalsEnum.LEAD_GENERATION) {
    dispatch(setAdCallAction(callToActionEnum.LearnMore));
    dispatch(setAdTypeAction(adTypes.CAROUSEL));
  } else {
    dispatch(setAdTypeAction(adTypes.SINGLE));
  }

  dispatch(saveMarketingBudgetsAction(adConfig));

  dispatch({
    type: adActionTypes.SET_GOAL,
    payload: goal,
  });
};

export const setAdFacebookHeadlineAction = (text) => async (dispatch) => {
  dispatch({
    type: adActionTypes.SET_FACEBOOK_HEADLINE,
    payload: text,
  });

  const violateWords = await getFairHousingCheckAction(text);

  dispatch({
    type: adActionTypes.SET_FACEBOOK_HEADLINE_CHECK,
    payload: Array.isArray(violateWords) ? violateWords : [],
  });
};

export const setAdFacebookPrimaryTextAction = (text) => async (dispatch) => {
  dispatch({
    type: adActionTypes.SET_FACEBOOK_PRIMARY_TEXT,
    payload: text,
  });

  const violateWords = await getFairHousingCheckAction(text);

  dispatch({
    type: adActionTypes.SET_FACEBOOK_PRIMARY_CHECK,
    payload: Array.isArray(violateWords) ? violateWords : [],
  });
};

export const setAdGoogleHeadlineAction = (headline) => async (dispatch) => {
  dispatch({
    type: adActionTypes.SET_GOOGLE_HEADLINE,
    payload: headline,
  });

  const violateWords = await getFairHousingCheckAction(headline);

  dispatch({
    type: adActionTypes.SET_GOOGLE_HEADLINE_CHECK,
    payload: Array.isArray(violateWords) ? violateWords : [],
  });
};

export const setAdGooglePrimaryTextAction = (primaryText) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_GOOGLE_PRIMARY_TEXT,
    payload: primaryText,
  });
};

export const setAdHouseFbPhotosSingleAction = (housePhoto) => (dispatch) =>
  dispatch({
    type: adActionTypes.SET_HOUSE_FB_PHOTOS_SINGLE,
    payload: housePhoto,
  });

export const setAdHouseGooglePhotosSingleAction = (housePhoto) => (dispatch) =>
  dispatch({
    type: adActionTypes.SET_HOUSE_GOOGLE_PHOTOS_SINGLE,
    payload: housePhoto,
  });

export const setAdHousePhotosCarouselAction = (housePhotos) => (dispatch) =>
  dispatch({
    type: adActionTypes.SET_HOUSE_PHOTOS_CAROUSEL,
    payload: housePhotos,
  });

  export const setIsComingSoon =
  (isComingSoon) => (dispatch) => {
    dispatch({
      type: adActionTypes.SET_IS_COMING_SOON,
      payload: isComingSoon,
    });
  };

export const setHousePhotoAction = (photo) => (dispatch) => {
  const store = getStore();

  const ad = selectAd(store);
  const adType = selectAdType(store);
  const adHousePhotosCarousel = selectAdHousePhotosCarousel(store);
  const goal = selectAdGoal(store);
  const photosMaxCount = getMaxPhotoCount(goal);
  switch (adType) {
    case adTypes.SINGLE:
      if (ad.adMediaType === adMediaTypesEnum.Facebook) {
        return dispatch(setAdHouseFbPhotosSingleAction([photo]));
      } else {
        return dispatch(setAdHouseGooglePhotosSingleAction([photo]));
      }
    case adTypes.CAROUSEL:
      if (adHousePhotosCarousel.includes(photo)) {
        return dispatch(
          setAdHousePhotosCarouselAction(
            adHousePhotosCarousel.filter((p) => photo !== p)
          )
        );
      }

      if (adHousePhotosCarousel.length === photosMaxCount) {
        adHousePhotosCarousel[adHousePhotosCarousel.length - 1] = photo;
        return dispatch(setAdHousePhotosCarouselAction(adHousePhotosCarousel));
      }

      // eslint-disable-next-line no-use-before-define
      return dispatch(
        setAdHousePhotosCarouselAction([...adHousePhotosCarousel, photo])
      );
    default:
      break;
  }
};

export const setConvertedHousePhotosAction = (housePhotos) => (dispatch) =>
  dispatch({
    type: adActionTypes.SET_CONVERTED_HOUSE_PHOTOS,
    payload: housePhotos,
  });

export const setHousePhotosHeader = (housePhoto) => (dispatch) =>
  dispatch({
    type: adActionTypes.SET_GENERATED_PHOTOS,
    payload: housePhoto,
  });

export const applyChooseImagesAction = () => async (dispatch) => {
  dispatch(setStepLoadingAction(true));
  dispatch(setPromoteListingStepAction(promoteStepsEnum.ReviewYourAd));
  const store = getStore();

  try {
    const adType = selectAdType(store);
    const ad = selectAd(store);
    const adHouseFbPhotosSingle = selectAdFbHousePhotosSingle(store);
    const adHouseGooglePhotosSingle = selectAdGoogleHousePhotosSingle(store);

    const adHousePhotosSingle =
      ad.adMediaType === adMediaTypesEnum.Facebook
        ? adHouseFbPhotosSingle
        : adHouseGooglePhotosSingle;
    const adHousePhotosCarousel = selectAdHousePhotosCarousel(store);

    const adHousePhotos =
      adType === adTypes.SINGLE ? adHousePhotosSingle : adHousePhotosCarousel;

    const requestBody = adHousePhotos.map((item, key) => ({
      id: key,
      data: item,
    }));

    const response = await axios.post(
      networkActions.DOWNLOAD_IMAGES,
      requestBody
    );

    dispatch(setConvertedHousePhotosAction(response.data));
    dispatch(setStepLoadingAction(false));
  } catch (error) {
    dispatch(setStepLoadingAction(false));
    notificationsServices.error(error);
  }
};

export const setAdLogoTypeAction = (logoType) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_LOGO_TYPE,
    payload: logoType,
  });
};

export const setAdBudgetAction = (budget) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_BUDGET,
    payload: budget,
  });
};

export const emptyAdBudgetAction = () => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_BUDGET,
    payload: { id: null, price: null },
  });
};

export const emptyAdDataAction = () => (dispatch) => {
  dispatch({
    type: adActionTypes.EMPTY_AD_DATA,
  });
};

export const setAdDetails = (adDetails) => (dispatch) => {
  /* eslint-disable-next-line */
  let conversionReportTotal = null;
  if (Array.isArray(adDetails.conversionReport)) {
    conversionReportTotal = adDetails.conversionReport
      ?.map((x) => x.clicks)
      .reduce((a, b) => a + b, 0);
  }
  dispatch({
    type: adActionTypes.SET_AD_DETAILS,
    payload: {
      ...adDetails,
      conversionReportTotal,
      address: formatAddressString(adDetails.address),
    },
  });
};

export const setMapChartData = (mapChartData) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_MAP_CHART_DATA,
    payload: mapChartData,
  });
};

export const setDeviceChartData = (deviceChartData) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_DEVICE_CHART_DATA,
    payload: deviceChartData,
  });
};

export const setAdDetailsLoading = (adDetailsLoading) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_DETAILS_LOADING,
    payload: adDetailsLoading,
  });
};

export const setAdDetailsError = () => (dispatch) => {
  dispatch({ type: adActionTypes.SET_AD_DETAILS_ERROR });
};

export const setMapChartDataMax = (maxValue) => (dispatch) => {
  dispatch({ type: adActionTypes.SET_MAP_CHART_DATA_MAX, payload: maxValue });
};

export const loadAdDetails = (adId, userId) => async (dispatch) => {
  try {
    dispatch(setAdDetailsLoading(true));
    const response = await axios.get(networkActions.GET_INSIGHT_DETAILS, {
      params: { adId, userId },
    });

    if (response.status === 200 && response.data) {
      dispatch(setAdDetails(response.data));

      if (Array.isArray(response.data.dmaReport)) {
        const mapData = prepareMapData(response.data.dmaReport, "impressions");
        const values = mapData.map((x) => x.value);
        dispatch(setMapChartDataMax(Math.max(...values)));
        dispatch(setMapChartData(mapData));
      }

      if (Array.isArray(response.data.devicesReport)) {
        const deviceData = prepareDeviceChartData(response.data.devicesReport);
        dispatch(setDeviceChartData(deviceData));
      }

      dispatch(setAdDetailsLoading(false));
    }
  } catch (error) {
    dispatch(setAdDetailsError());
    notificationsServices.error(error);
  }
};

export const setAdPreviewInfoLoading = (loadingState) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_PREVIEW_INFO_LOADING,
    payload: loadingState,
  });
};

export const setAdPreviewInfoError = (errorState) => (dispatch) => {
  dispatch({
    type: adActionTypes.SET_AD_PREVIEW_INFO_ERROR,
    payload: errorState,
  });
};

export const emptyAdPreviewInfo = () => (dispatch) => {
  dispatch({ type: adActionTypes.SET_AD_PREVIEW_INFO, payload: {} });
};

export const getAdPreviewInfo = (internalAdId, userId) => async (dispatch) => {
  try {
    dispatch(setAdPreviewInfoLoading(true));
    const response = await axios.get(networkActions.GET_AD_PREVIEW_INFO, {
      params: { internalAdId, userId },
    });

    if (response.status === 200 && response.data) {
      dispatch({
        type: adActionTypes.SET_AD_PREVIEW_INFO,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch(setAdPreviewInfoLoading(false));
    notificationsServices.error(error);
  }
};
