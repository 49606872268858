import moment from "moment";
import insightActionTypes from "./insightActionTypes";
import axios from "../../../api/index";
import {
  networkActions,
  promoteAdsFilterObjectInitialState,
} from "../../../constants/constants";
import { getStore } from "../../index";
import {
  selectPromoteAdsFilterObject,
  selectPromotedAds,
} from "../../selectors/insightSelector";
import {
  prepareDeviceChartData,
  prepareMapData,
} from "../../../helpers/charts";
import { notificationsServices } from "../../../helpers/notifications";
import { goalsEnum } from "../../../constants/enums";

const defaultStartDate = moment()
  .subtract(2, "week")
  .utc()
  .format("YYYY-MM-DD");
const defaultEndDate = moment().utc().format("YYYY-MM-DD");

export const setPromotedAdInfoLoadingAction = (loading) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_PROMOTED_AD_INFO_LOADING,
    payload: loading,
  });
};

export const setShareMetricLoadingAction = (loading) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SHARE_METRIC_LOADER,
    payload: loading,
  });
};

export const setInsightErrorAction = (error) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_ERROR,
    payload: error,
  });
};

export const savePromotedAdsAction = (promotedAds) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SAVE_PROMOTED_AD_INFO,
    payload: promotedAds,
  });
};

export const saveFilteredPromotedAdsAction =
  (filteredPromoteAds) => (dispatch) => {
    const total = {
      spend: 0,
      impressions: 0,
      adClicks: 0,
      leads: 0,
      isOnlyTrafficAds: true,
    };

    filteredPromoteAds.forEach(
      ({ spend, impressions, adClicks, leads, goal }) => {
        total.spend += spend;
        total.impressions += impressions;
        total.adClicks += adClicks;
        total.leads += leads;

        if (goalsEnum[goal] === goalsEnum.LEAD_GENERATION) {
          total.isOnlyTrafficAds = false;
        }
      }
    );

    dispatch({
      type: insightActionTypes.SAVE_FILTERED_PROMOTED_ADS,
      payload: { filteredPromoteAds, total },
    });
  };

export const setInsightEffectivenessLoading = (loading) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_EFFECTIVENESS_LOADING,
    payload: loading,
  });
};

export const setInsightEffectiveness = (effectiveness) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_EFFECTIVENESS,
    payload: effectiveness,
  });
};

export const setInsightExposure = (exposure) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_EXPOSURE,
    payload: exposure,
  });
};
export const setInsightExposureLoading = (loading) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_EXPOSURE_LOADING,
    payload: loading,
  });
};

export const setInsightDevices = (devices) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_DEVICES,
    payload: devices,
  });
};

export const setInsightDevicesLoading = (loading) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_DEVICES_LOADING,
    payload: loading,
  });
};

export const setInsightDevicesChartData = (chartData) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_DEVICES_CHART_DATA,
    payload: chartData,
  });
};

export const setInsightCitiesChartData = (chartData) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_CITIES_CHART_DATA,
    payload: chartData,
  });
};

export const setInsightCitiesChartMax = (chartMax) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_CITIES_CHART_DATA_MAX,
    payload: chartMax,
  });
};

export const setInsightCities = (cities) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_CITIES,
    payload: cities,
  });
};

export const setInsightCitiesLoading = (loading) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_CITIES_LOADING,
    payload: loading,
  });
};

export const setInsightConversion = (conversions) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_CONVERSION,
    payload: conversions,
  });
};

export const setInsightConversionLoading = (loading) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_INSIGHT_CONVERSION_LOADING,
    payload: loading,
  });
};

const filterPromotedAds = (data, filter) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, item] of Object.entries(filter)) {
    if (key === "platformsFilter") {
      data = data.filter((o) =>
        (Array.isArray(item) && item.length > 0
          ? JSON.stringify(o.platforms) === JSON.stringify(item)
          : true));
    }

    if (key === "goalsFilter") {
      data = data.filter((o) =>
        (Array.isArray(item) && item.length > 0 ? item.includes(o.goal) : true));
    }

    // if (key === "dateRange") {
    //     const rangeFilter = filter.dateRange;
    //
    //     if (Array.isArray(rangeFilter) &&
    //         rangeFilter.length === 2 && rangeFilter[0] && rangeFilter[1]
    //     ) {
    //         data = data.filter(
    //             o => new Date(o.start) >= new Date(rangeFilter[0]) &&
    //                 new Date(o.start) <= new Date(rangeFilter[1])
    //         );
    //     }
    // }
  }

  return data;
};

export const getInsightEffectiveness =
  ({ listingId, startDate = defaultStartDate, endDate = defaultEndDate, userId }) =>
  async (dispatch) => {
    try {
      dispatch(setInsightEffectivenessLoading(true));
      const response = await axios.post(
        networkActions.GET_INSIGHT_EFFECTIVENESS,
        {
          listingId,
          startDate,
          endDate,
        },
        {
          params: { userId }
        }
      );
      if (Array.isArray(response?.data?.adEffectiveness)) {
        dispatch(setInsightEffectiveness(response.data));
      } else {
        dispatch(
          setInsightEffectiveness({
            adEffectiveness: [],
            hasAd: false,
          })
        );
        dispatch(setInsightEffectivenessLoading(false));
      }
    } catch (error) {
      dispatch(
        setInsightEffectiveness({
          adEffectiveness: [],
          hasAd: false,
        })
      );
      dispatch(setInsightEffectivenessLoading(false));
      notificationsServices.error(error);
    }
  };

export const getInsightExposure =
  ({ listingId, startDate = defaultStartDate, endDate = defaultEndDate, userId }) =>
  async (dispatch) => {
    try {
      dispatch(setInsightExposureLoading(true));
      const response = await axios.post(
        networkActions.GET_INSIGHT_EXPOSURE,
        {
          listingId,
          startDate,
          endDate,
        },
        { params: { userId } }
        );
      if (Array.isArray(response?.data)) {
        dispatch(setInsightExposure(response.data));
      } else {
        dispatch(setInsightExposure([]));
        dispatch(setInsightExposureLoading(false));
      }
    } catch (error) {
      dispatch(setInsightExposure([]));
      dispatch(setInsightExposureLoading(false));
      notificationsServices.error(error);
    }
  };

export const getInsightDevices =
  ({ listingId, startDate = defaultStartDate, endDate = defaultEndDate, userId }) =>
  async (dispatch) => {
    try {
      dispatch(setInsightDevicesLoading(true));
      const response = await axios.post(
        networkActions.GET_INSIGHT_DEVICE,
        {
          listingId,
          startDate,
          endDate,
        },
        { params: { userId } }
      );

      if (Array.isArray(response?.data)) {
        const deviceData = prepareDeviceChartData(response.data);
        dispatch(setInsightDevices(response.data));
        dispatch(setInsightDevicesChartData(deviceData));
      } else {
        dispatch(setInsightDevices([]));
        dispatch(setInsightDevicesChartData({}));
        dispatch(setInsightDevicesLoading(false));
      }
    } catch (error) {
      dispatch(setInsightDevices([]));
      dispatch(setInsightDevicesChartData({}));
      dispatch(setInsightDevicesLoading(false));
      notificationsServices.error(error);
    }
  };

export const getInsightCitiesConversion =
  ({ listingId, startDate = defaultStartDate, endDate = defaultEndDate, userId }) =>
  async (dispatch) => {
    try {
      dispatch(setInsightCitiesLoading(true));
      dispatch(setInsightConversionLoading(true));
      const response = await axios.post(
        networkActions.GET_INSIGHT_CITY_CONVERSION,
        {
          listingId,
          startDate,
          endDate,
        },
        { params: { userId } }
      );
      if (response.data) {
        const { cityReport, conversionReport } = response.data;
        if (Array.isArray(cityReport)) {
          const mapData = prepareMapData(cityReport);
          const values = mapData.map((x) => x.value);
          dispatch(setInsightCities(cityReport));
          dispatch(setInsightCitiesChartData(mapData));
          dispatch(setInsightCitiesChartMax(Math.max(...values)));
        } else {
          dispatch(setInsightCitiesLoading(false));
          dispatch(setInsightCities([]));
          dispatch(setInsightCitiesChartData([]));
        }
        if (Array.isArray(conversionReport)) {
          dispatch(setInsightConversion(conversionReport));
        } else {
          dispatch(setInsightConversion([]));
          dispatch(setInsightConversionLoading(false));
        }
      } else {
        dispatch(setInsightConversion([]));
        dispatch(setInsightConversionLoading(false));
        dispatch(setInsightCitiesLoading(false));
        dispatch(setInsightCities([]));
        dispatch(setInsightCitiesChartData([]));
      }
    } catch (error) {
      dispatch(setInsightCitiesLoading(false));
      dispatch(setInsightCities([]));
      dispatch(setInsightCitiesChartData([]));
      dispatch(setInsightConversionLoading(false));
      dispatch(setInsightConversion([]));
      notificationsServices.error(error);
    }
  };
// don't use action need delete after testing  and deployment in production
// export const getInsightConversion =
//     ({ listingId, startDate = defaultStartDate, endDate = defaultEndDate }) =>
//         async (dispatch) => {
//             try {
//                 dispatch(setInsightConversionLoading(true));
//                 const response = await axios.post(networkActions.GET_INSIGHT_CONVERSION, {
//                     listingId,
//                     startDate,
//                     endDate,
//                 });

//                 if (Array.isArray(response?.data)) {
//                     dispatch(setInsightConversion(response.data));
//                 } else {
//                     dispatch(setInsightConversion([]));
//                     dispatch(setInsightConversionLoading(false));
//                 }
//             } catch (error) {
//                 dispatch(setInsightConversionLoading(false));
//                 dispatch(setInsightConversion([]));
//                 notificationsServices.error(error);
//             }
//         };

export const savePromotedAdsFilterAction = (filter) => (dispatch) => {
  dispatch({
    type: insightActionTypes.SET_PROMOTED_ADS_FILTER,
    payload: filter,
  });
};

export const emptyPromotedAdsAction = () => async (dispatch) => {
  dispatch(savePromotedAdsAction([]));
  dispatch(saveFilteredPromotedAdsAction([]));
  dispatch(savePromotedAdsFilterAction(promoteAdsFilterObjectInitialState));
};

export const emptyAnalyticsAction = () => async (dispatch) => {
  dispatch(
    setInsightEffectiveness({
      adEffectiveness: [],
      hasAd: false,
    })
  );
  dispatch(setInsightDevices([]));
  dispatch(setInsightDevicesChartData({}));
  dispatch(setInsightExposure([]));
  dispatch(setInsightCities([]));
  dispatch(setInsightCitiesChartData([]));
  dispatch(setInsightConversion([]));
};

export const getPromotedAdsAction = (listingId, userId) => async (dispatch) => {
  try {
    dispatch(setPromotedAdInfoLoadingAction(true));

    const response = await axios.get(`${networkActions.GET_PROMOTED_ADS}`, {
      params: { listingId, userId },
    });

    if (Array.isArray(response?.data)) {
      const store = getStore();
      const promotedAdsFilterObject = selectPromoteAdsFilterObject(store);
      dispatch(savePromotedAdsAction(response.data));
      dispatch(
        saveFilteredPromotedAdsAction(
          filterPromotedAds(response.data, promotedAdsFilterObject)
        )
      );
    }
  } catch (error) {
    dispatch(setPromotedAdInfoLoadingAction(false));
    notificationsServices.error(error);
  }
};

export const setPromoteAdsFilterAction = (key, filterBody) => (dispatch) => {
  const store = getStore();

  const promotedAdsFilterObject = selectPromoteAdsFilterObject(store);
  const promotedAds = selectPromotedAds(store);

  const promotedAdsFilterObjectCopy = { ...promotedAdsFilterObject };
  promotedAdsFilterObjectCopy[key] = filterBody;

  dispatch(savePromotedAdsFilterAction(promotedAdsFilterObjectCopy));
  const promotedAdsCopy = [...promotedAds];
  dispatch(
    saveFilteredPromotedAdsAction(
      filterPromotedAds(promotedAdsCopy, promotedAdsFilterObjectCopy)
    )
  );
};

export const emptyInsightInfo = () => (dispatch) => {
  dispatch({
    type: insightActionTypes.EMPTY_INSIGHT_INFO,
  });
};
