const adActionTypes = {
    SAVE_INVOICE_INFO: "save_invoice_info",
    SAVE_AD_LOADING: "save_ad_loading",
    SUCCESSFULLY_CANCEL_AD: "successfully_cancel_ad",
    CANCEL_AD_LOADING: "cancel_ad_loading",
    CANCEL_AD_ERROR: "cancel_ad_error",
    SAVE_AD_NOTIFICATION: "save_ad_notification",
    SET_LISTING_ID: "set_listing_id",
    SET_GOAL: "set_goal",
    SET_AD_LOGO_TYPE: "set_ad_logo_type",
    SET_AD_MEDIA_TYPES: "set_ad_media_types",
    SET_FACEBOOK_HEADLINE: "set_facebook_headline",
    SET_FACEBOOK_HEADLINE_CHECK: "set_facebook_headline_check",
    SET_FACEBOOK_HEADLINE_CHECK_LOADING: "set_facebook_headline_check_loading",
    SET_FACEBOOK_PRIMARY_TEXT: "set_facebook_primary_text",
    SET_FACEBOOK_PRIMARY_CHECK: "set_facebook_primary_check",
    SET_FACEBOOK_PRIMARY_CHECK_LOADING: "set_facebook_primary_check_loading",
    SET_GOOGLE_HEADLINE: "set_google_headline",
    SET_GOOGLE_HEADLINE_CHECK: "set_google_headline_check",
    SET_GOOGLE_HEADLINE_CHECK_LOADING: "set_google_headline_check_loading",
    SET_GOOGLE_PRIMARY_TEXT: "set_google_primary_text",
    SET_AD_CALL_ACTION: "set_ad_call_action",
    SET_HOUSE_FB_PHOTOS_SINGLE: "set_house_fb_photos_single",
    SET_HOUSE_GOOGLE_PHOTOS_SINGLE: "set_house_google_photos_single",
    SET_HOUSE_PHOTOS_CAROUSEL: "set_house_photos_carousel",
    SET_HOUSE_BASE64_PHOTOS: "set_house_base64_photos",
    SET_CONVERTED_HOUSE_PHOTOS: "set_converted_house_photos",
    SET_GENERATED_PHOTOS: "SET_GENERATED_PHOTOS",
    SET_AD_TYPE: "set_ad_type",
    SET_BUDGET: "set_budget",
    SET_AD_DETAILS: "set_ad_details",
    SET_MAP_CHART_DATA: "set_map_chart_data",
    SET_MAP_CHART_DATA_MAX: "set_map_chart_data_max",
    SET_DEVICE_CHART_DATA: "set_device_chart_data",
    SET_AD_DETAILS_LOADING: "set_ad_details_loading",
    SET_AD_DETAILS_ERROR: "set_ad_details_error",
    SET_AD_PREVIEW_INFO: "set_ad_preview_info",
    SET_AD_PREVIEW_INFO_LOADING: "set_ad_preview_info_loading",
    SET_AD_PREVIEW_INFO_ERROR: "set_ad_preview_info_error",
    SET_AD_CONFIGS_BY_GOAL: "set_ad_configs_by_goal",
    EMPTY_AD_DATA: "empty_ad_data",
    SET_IS_COMING_SOON: "set_is_coming_soon",

};

export default adActionTypes;
