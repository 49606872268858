import moment from "moment";

export const CLIENT_FINDER_API_KEY = "CLIENT_FINDER_API_KEY";

export const isSSOAuth =
  window.location.origin === process.env.REACT_APP_AD_PRO_ORIGIN;

export const networkActions = {
  GET_REFRESH_TOKEN: "User/refresh-token",
  GET_PERSONAL_DATA: "User/personal-data",
  GET_USER_BRANDING: "User/branding",
  AUTHENTICATE_SSO: "User/authenticate-sso",
  GET_AGENT_LISTINGS: "Listing/agent-listings",
  GET_LISTING_INFO: "Listing/info",
  GET_LISTING_IMAGES: "Listing/images",
  GET_LISTING_ADDRESS: "Listing/address",
  FAIR_HOUSING_CHECK: "Listing/fairHousing/check",
  GET_MARKETING_BUDGETS: "Payment/marketing-budgets",
  GET_AMP_BALANCE: "Depayment/amp/balance",
  PUBLISH_AD: "Ad/fb/publish",
  CANCEL_AD: "Ad/fb/cancel",
  GET_AD_PREVIEW_INFO: "Ad/ad/preview",
  DOWNLOAD_IMAGES: "Operation/image/download",
  SHARE_METRIC: "Operation/save-analytics-pdf",
  GET_PROMOTED_ADS: "Insight/promoted-info",
  GET_INSIGHT_DETAILS: "Insight/details",
  GET_AGENT_LIST_OF_LISTINGS: "listing/agent-listings-detailed",
  GET_INSIGHT_EFFECTIVENESS: "insight/ad/effectiveness",
  GET_INSIGHT_EXPOSURE: "insight/listing/exposure",
  GET_INSIGHT_DEVICE: "insight/listing/device",
  // unusable request endpoints
  // GET_INSIGHT_CONVERSION: "insight/listing/conversion",
  // GET_INSIGHT_CITY: "insight/listing/city",
  GET_INSIGHT_CITY_CONVERSION: "insight/listing/city-conversion",
  EMAIL_TEMPLATE: "email/template",
  OPERATION_SAVE_INVOICE: "Operation/saveInvoice",
  SEND_SUPPORT_EMAIL: "email/send-support-email",
  GET_FAQ_CONTENT: "Content/type/1",
  GET_MANAGER_INFO: "Managers/manager-info",
  DELEGATION_ACCESS: "/DelegateAccess",
  DELEGATION_ACCESS_OWNER: "/DelegateAccess/owners",
  ACCEPT_DELEGATION_ACCESS: "/DelegateAccess/accept",
  MANAGER_TARGETING: "Managers/targetting-options",
  MANAGER_PUBLISH_IMAGES: "Ad/fb/image/publish",
  MANAGER_PUBLISH_VIDEO: "Ad/fb/video/publish",
  MANAGER_UPLOAD_VIDEO: "Ad/fb/video/upload",
};

export const routesPath = {
  AUTHENTICATE: "authenticate",
  ANALYTICS: "analytics",
  ALL: "all",
  TRAFFIC: "traffic",
  PERFORMANCE: "performance",
  ISSUE: "issue",
  SSO: "auth",
  MAINTENANCE: "maintenance",
  DELEGATE: "delegateUser",
  MANAGER_AD_ANALYTICS: "ad-analytics",
};

export const listingFilterTypesEnum = {
  address: "address",
  listingId: "listingId",
};

export const listingFilterTypes = [
  { key: listingFilterTypesEnum.address, displayName: "address" },
  { key: listingFilterTypesEnum.listingId, displayName: "listing ID" },
  // {key: "agent", displayName: "agent"}
];

export const facebookHeadlineMaxLength = 40;
export const googleHeadlineMaxLength = 40;
export const facebookPrimaryTextMaxLength = 125;
export const googlePrimaryTextMaxLength = 90;

export const adPhotosMinCount = 3;
export const adPhotosMaxCount = 3;
export const adLeadPhotoMaxCount = 5;

export const adCallActions = [
  { name: "ShopNow", title: "Shop now" },
  { name: "LearnMore", title: "Learn more" },
  { name: "RequestTime", title: "Request time" },
];

export const CardTypes = {
  Visa: "Visa",
  Mastercard: "Mastercard",
  AmericanExpress: "American Express",
};

export const notApplicable = "N/A";

export const trafficKey = "LINK_CLICKS";
export const leadKey = "LEAD_GENERATION";

export const goalTypesMapper = {
  [trafficKey]: "Impressions",
  [leadKey]: "Leads",
};

export const mediaTypesMapper = {
  Facebook_Instagram: {
    title: "Facebook + Instagram",
    key: 1,
  },
  Google: {
    title: "Google",
    key: 2,
  },
};

export const dayStatuses = {
  FINISHED: "Finished",
  ENDED: "Ended",
};

export const callToActionTypes = ["Shop now", "Learn more", "Request time"];

export const feePercent = 0.1;

export const valueFormat = {
  K: "K",
  Percent: "%",
  Regular: "Regular",
};

export const creditCardValidationMessages = {
  name: "Please enter the name as it appears on your card",
  cardNumber: "Please enter the number as it appears on your card",
  expiration: "Please enter correct Card Expiry date",
  zipCode: "Please enter correct US ZIP code",
};

export const invoiceTypes = {
  APPROVED: "approved",
  PENDING: "pending",
};

export const conversionReportTypes = {
  None: "",
  EmailListingShare: "Email Listing - Share",
  EmailMe: "Email Me",
  PrintListing: "Print Listing",
  AddToCalendarOpenHouse: "Add to Calendar - Open",
  SaveAListing: "Save a Listing",
  FloorPlan: "Floor Plan",
  MapLocation: "Map Location",
  VirtualTour: "Virtual Tour",
  VideoClickOnLP: "Video Click on LP",
};

export const fileNameDateFormat = "YYYY-MM-DD_hh:mm:ss";

const defaultStartDate = moment().subtract(2, "week").toDate();
const defaultEndDate = moment().toDate();

export const promoteAdsFilterObjectInitialState = {
  platformsFilter: [],
  goalsFilter: [],
  dateRange: [defaultStartDate, defaultEndDate],
};

export const areaChartColor = {
  ad: "#009CBD",
  session: "#100b28",
};

export const buttonTypesForCss = {
  totalTraffic: 1,
  brandColor: 2,
  adPerformance: 3,
  none: 4,
};

export const CHART_CSS_VAR_PREFIX = "chart-exposure";

export const listingTabsNamesMapper = {
  all: "0",
  none: "1",
  in: "2",
  ended: "3",
  // inactive: "4", HideInactive
};

/**
 * Ad status mapper for Ad Listing, Paid Ad Performance pages components.
 * @see <a href="https://docs.google.com/spreadsheets/d/1B1RvbycKEf9QulaXS
 * u0cr3YRk_GCWcTxo2LzUnV6D-Y/edit#gid=0">Ad Statuses more info</a>
 */
export const adCfStatusMapper = {
  ReadyForPayment: {
    value: 0,
    name: "ReadyForPayment",
    tableButton: {
      listingBtnType: buttonTypesForCss.adPerformance,
      performanceBtnType: buttonTypesForCss.adPerformance,
      title: "Ad Performance",
    },
    promoteButton: {
      className: "budget-approval",
      title: "Budget Approval",
      disabled: true,
    },
    filterIndex: listingTabsNamesMapper.none,
    allActiveListingsStatus: {
      className: "no-ads",
      title: "Budget Approval",
    },
    paidAdsPerformanceStatus: {
      className: "no-ads",
      title: "Budget Approval",
    },
  },
  WaitingForPaymentApproval: {
    value: 1,
    name: "WaitingForPaymentApproval",
    tableButton: {
      listingBtnType: buttonTypesForCss.adPerformance,
      performanceBtnType: buttonTypesForCss.adPerformance,
      title: "Ad Performance",
    },
    promoteButton: {
      className: "budget-approval",
      title: "Budget Approval",
      disabled: true,
    },
    filterIndex: listingTabsNamesMapper.none,
    allActiveListingsStatus: {
      className: "no-ads",
      title: "Budget Approval",
    },
    paidAdsPerformanceStatus: {
      className: "no-ads",
      title: "Budget Approval",
    },
  },
  ReadyForPublish: {
    value: 2,
    name: "ReadyForPublish",
    tableButton: {
      listingBtnType: buttonTypesForCss.adPerformance,
      performanceBtnType: buttonTypesForCss.adPerformance,
      title: "Ad Performance",
    },
    promoteButton: {
      className: "budget-approval",
      title: "Budget Approval",
      disabled: true,
    },
    filterIndex: listingTabsNamesMapper.none,
    allActiveListingsStatus: {
      className: "no-ads",
      title: "Budget Approval",
    },
    paidAdsPerformanceStatus: {
      className: "no-ads",
      title: "Budget Approval",
    },
  },
  Active: {
    value: 3,
    name: "Active",
    tableButton: {
      listingBtnType: buttonTypesForCss.adPerformance,
      performanceBtnType: buttonTypesForCss.totalTraffic,
      title: "Ad Performance",
      showCancelButton: true,
    },
    promoteButton: {
      className: "in-promotion",
      title: "In Promotion",
      disabled: true,
    },
    filterIndex: listingTabsNamesMapper.in,
    allActiveListingsStatus: {
      className: "running",
      title: "Running",
    },
    paidAdsPerformanceStatus: {
      className: "running",
    },
  },
  Published: {
    value: 3,
    name: "Published",
    tableButton: {
      listingBtnType: buttonTypesForCss.adPerformance,
      performanceBtnType: buttonTypesForCss.adPerformance,
      title: "Ad Performance",
      showCancelButton: true,
    },
    promoteButton: {
      className: "in-review",
      title: "In Review",
      disabled: true,
    },
    filterIndex: listingTabsNamesMapper.none,
    allActiveListingsStatus: {
      className: "no-ads",
      title: "In Review",
    },
    paidAdsPerformanceStatus: {
      className: "no-ads",
      title: "In Review",
    },
  },
  Canceled: {
    value: 4,
    name: "Canceled",
    tableButton: {
      listingBtnType: buttonTypesForCss.adPerformance,
      performanceBtnType: buttonTypesForCss.totalTraffic,
      title: "Ad Performance",
    },
    promoteButton: {
      className: "promote-again",
      title: "Promote Again",
      disabled: false,
      showInPerformance: true,
    },
    filterIndex: listingTabsNamesMapper.ended,
    allActiveListingsStatus: {
      className: "ended",
      title: "Ended",
    },
    paidAdsPerformanceStatus: {
      className: "ended",
      title: "Ended",
    },
  },
  Ended: {
    value: 5,
    name: "Ended",
    tableButton: {
      listingBtnType: buttonTypesForCss.adPerformance,
      performanceBtnType: buttonTypesForCss.totalTraffic,
      title: "Ad Performance",
    },
    filterIndex: listingTabsNamesMapper.ended,
    promoteButton: {
      className: "promote-again",
      title: "Promote Again",
      disabled: false,
      showInPerformance: true,
    },
    allActiveListingsStatus: {
      className: "ended",
      title: "Ended",
    },
    paidAdsPerformanceStatus: {
      className: "ended",
      title: "Ended",
    },
  },
  None: {
    value: 6,
    name: "None",
    promoteButton: {
      className: "",
      title: "Promote Listing",
      disabled: false,
    },
    tableButton: {
      listingBtnType: buttonTypesForCss.brandColor,
      performanceBtnType: buttonTypesForCss.totalTraffic,
      title: "Promote Listing",
    },
    filterIndex: listingTabsNamesMapper.none,
    allActiveListingsStatus: {
      className: "no-ads",
      title: "No Ad",
    },
    paidAdsPerformanceStatus: {
      className: "",
    },
  },
};

export const pdfFileNameKeys = {
  budgetApproval: "BudgetApproval",
  creditCard: "CreditCard",
  amp: "AMP",
  adDetails: "adDetails",
  all: "all",
  traffic: "traffic",
};

export const adMediaTypesMapper = {
  Google: "GG",
  Facebook_Instagram: "FB_IG",
};

export const paymentTypesMapper = {
  0: "AMP",
  1: "CreditCard",
};

export const displayCssTypeMapper = {
  none: "none",
  flex: "flex",
};

export const SAVE_TYPE = "save";
export const SHARE_TYPE = "share";

export const pageType = {
  AdDetails: "AdDetails",
  TotalListingTraffic: "TotalListingTraffic",
};

export const emailTemplate = {
  ShareAnalytics: "ShareAnalytics",
};

export const alertMessages = {
  shareSuccess: "The analytics was successfully shared with your client(s)",
  shareError: "Something went wrong",
};

export const agentListOfListingsInfoInitial = {
  total: {
    price: 0,
    adSpend: 0,
    adReach: 0,
    adStatus: "",
    totalViews: 0,
    totalLeads: 0,
  },
  filterTabs: {
    [listingTabsNamesMapper.all]: { value: 0, title: "All Listings" },
    [listingTabsNamesMapper.none]: { value: 0, title: "Promotion not started" },
    [listingTabsNamesMapper.in]: { value: 0, title: "In Promotion" },
    [listingTabsNamesMapper.ended]: { value: 0, title: "Promotion Ended" },
    // [listingTabsNamesMapper.inactive]: { value: 0, title: "Inactive" }, HideInactive
  },
};

export const adTypes = {
  SINGLE: "SingleImage",
  CAROUSEL: "Carousel",
};

export const promoteGoalText = {
  clicks:
    "Casting a wide net with a focus on awareness, this ad works to bring max # of people to listing page to learn about you/listing.  Targeting people nearby in market for real estate, finance interest, etc., who engaged with similar listings recently.",
  leads:
    "Ad targets high-intent people near the listing in market for real estate, finance interest, etc., & have engaged with similar listings recently. Ads are configured with a lead form to capture buyer leads, which will be sent directly to agents.",
};

export const issueTypesList = [
  "Accessing AdPro",
  "Payment Related Issues",
  "Finding Listings",
  "Listing Promotion",
  "Viewing Analytics",
  "Other",
];

export const pages = {
  homePage: "Home page",
  allYourActiveListings: "All of your active listings",
  paidAdPerformance: "Paid Ad Performance",
  yourAdDetails: "Your Ad Details",
  totalListingTraffic: "Total Listing Traffic",
  selectYourGoal: "Select Your Goal",
  chooseYourMedia: "Choose Your Media",
  reviewCopy: "Review Copy",
  chooseListingImages: "Choose Listing Images",
  previewYourAd: "Preview Your Ad",
  selectPaidMediaPackage: "Select Paid Media Package",
  pay: "Pay",
  confirmationPage: "Confirmation page",
};

export const promiseStatuses = {
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
};

export const googlePreviewNames = [
  "skyscraper",
  "wide-skyscraper",
  "small-square",
  "square",
  "embedded-rectangle",
  "big-rectangle",
  "vertical-rectangle",
  "half-page-block",
  "full-size-mobile-banner",
  "large-mobile",
  "banner",
  "full-size-ad",
  "large-full-size-block",
  "billboard",
];

export const ROLES = {
  AGENT: "Agent",
  REGIONALEXECUTIVE: "RegionalExecutive",
  REGIONALMANAGER: "RegionalManager",
  SALESMANAGER: "SalesManager",
  MANAGER: "MANAGER",
  MARKETINGMANAGER: "MarketingManager",
  EMPLOYEE: "Employee",
};

export const defaultTrafficPreview =
  "As a powerful partner in promoting your property, I am proud \nto share your listing traffic report, provided by our proprietary, \nAI-powered technology. With our commanding online presence and personalized distribution, we ensure your listing gets the exposure it deserves.\n\nPlease click below to view your metrics, and do not hesitate to reach out with any questions.\n\nThe next move is yours. ";

export const defaultAdDetailPreview =
  "As a powerful partner in promoting your property, I am proud \nto share your ad performance report, provided by our proprietary, AI-powered technology. With our commanding online presence and personalized distribution, we ensure your listing gets the exposure it deserves.\n\nPlease click below to view your metrics, and do not hesitate to reach out with any questions.\n\nThe next move is yours. ";

export const uiDateFormat = "MM/DD/YYYY";
export const uiDateFormatMobile = "MMM DD";

export const asanaFormLink =
  "https://form.asana.com/?k=iUKjts5Gt2dA4C3rHqE-IQ&d=14384507346757";

export const managerWriteCopyList = [
  { name: "Learn more", value: 2 },
  { name: "Shop now", value: 1 },
  { name: "Download", value: 4 },
  { name: "Sign up", value: 5 },
];

export const managerFacebookPlatform = "Facebook + Instagram";
