import { adPhotosMaxCount, adLeadPhotoMaxCount } from "../constants/constants";
import { goalsEnum } from "../constants/enums";

const getBase64 = (file, cb) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      cb(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", file);
  xhr.setRequestHeader(
    "accept",
    "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9"
  );
  xhr.setRequestHeader("accept-encoding", "gzip, deflate, br");
  xhr.setRequestHeader("accept-language", " en-US,en;q=0.9");
  xhr.setRequestHeader(
    "user-agent",
    "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.69 Safari/537.36"
  );
  xhr.send();
};

// eslint-disable-next-line max-len
export const isCompletedRange = (dateRanges) =>
  Array.isArray(dateRanges) &&
  dateRanges.length === 2 &&
  dateRanges[0] &&
  dateRanges[1];

export const getMaxPhotoCount = (goal) => {
  if (goal === goalsEnum.LEAD_GENERATION) {
    return adLeadPhotoMaxCount;
  }
  return adPhotosMaxCount;
};

export default getBase64;

export const primaryTextCrop = (primaryText) => {
  if (primaryText) {
    const maxPrimaryTextLength = 58;
    const primaryWithoutSpace = primaryText?.trim();
    if (primaryWithoutSpace?.length > maxPrimaryTextLength) {
      const resultText = primaryWithoutSpace.slice(0, maxPrimaryTextLength);
      return { text: `${resultText}...`, withMore: true };
    }
  }
  return { text: primaryText || "", withMore: false };
};
