import React from "react";
import { noop } from "lodash";

const ManagerAdSection = ({ onPlaceAd, onViewAd = noop }) => (
  <div className="manager-ad-section">
    <div className="manager-ad-title"> Your Ads</div>
    <div className="manager-buttons-group">
      <button className="manager-black-button" onClick={onPlaceAd}>
        Place New Ad
      </button>
      <button className="manager-black-button" onClick={onViewAd}>
        View Ad Analytics
      </button>
    </div>
  </div>
);

export default ManagerAdSection;
