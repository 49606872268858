import React, { useState, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { paymentSepsEnum, SocialEnum } from "../../../constants/enums";
import StepsCountColumn from "../promoteLayout/StepsCountColumn";
import NavigationText from "./NavigationText";
import AdView from "../../shared/AdView";
import AdPopup from "../../shared/AdPopup";
import Spinner from "../../shared/Spinner";
import {
  setPaymentListingStepAction,
  setPromoteListingStepAction,
} from "../../../store/actions/listing/listingActions";
import { setHousePhotosHeader } from "../../../store/actions/ad/adActions";
import {
  selectPromoteListingStep,
  selectStepLoading,
} from "../../../store/selectors/listingSelector";
import { notificationsServices } from "../../../helpers/notifications";
import SliderAds from "../../shared/AdView/SliderAds";
import { googlePreviewNames } from "../../../constants/constants";
import PortalCarouselImages from "./PortalCarouselImages";

const mainImageOptions = {
  width: 1080,
  height: 1080,
  useCORS: true,
  allowTaint: true,
};

const carouselOptions = {
  width: 540,
  height: 540,
  useCORS: true,
  allowTaint: true,
};

const generateImage = async (element, options) => {
  try {
    const canvas = await html2canvas(element, options);
    const imageUrl = canvas.toDataURL("image/png");
    return imageUrl;
  } catch (err) {
    return err;
  }
};

const PreviewYourAdView = () => {
  const [loading, setLoading] = useState(false);
  const [convertedRefs, setConvertedRefs] = useState([]);
  const [activeSocial, setActiveSocialView] = useState(SocialEnum.FACEBOOK);
  const [popupState, setPopupState] = useState("none");
  const mainImageRef = useRef(null);
  const sliderRef = useRef(null);
  const promoteListingStep = useSelector(selectPromoteListingStep);
  const stepLoading = useSelector(selectStepLoading);

  const dispatch = useDispatch();
  const googlePreviewRefs = useRef([]);
  googlePreviewRefs.current = googlePreviewNames.map(
    (_, key) => googlePreviewRefs[key] ?? createRef()
  );

  const goBack = () => {
    dispatch(setPromoteListingStepAction(promoteListingStep - 1));
  };

  const handlePurchaseAd = () => {
    setLoading(true);

    const mainImage = mainImageRef?.current;
    const scaleX = 1080 / mainImage.clientWidth;
    const scaleY = 1080 / mainImage.clientHeight;
    mainImage.style.transform = `scale(${scaleX}, ${scaleY})`;

    const getMainImage = generateImage(mainImage, mainImageOptions);

    const getCollapseImages = convertedRefs.map(
      (item) => generateImage(item.element, carouselOptions)
      // eslint-disable-next-line function-paren-newline
    );

    Promise.all([getMainImage, ...getCollapseImages])
      .then((imageUrls) => {
        setLoading(false);
        dispatch(setHousePhotosHeader(imageUrls));
        dispatch(
          setPaymentListingStepAction(paymentSepsEnum.SelectPaidMediaPackage)
        );
      })
      .catch(() => {
        setLoading(false);
        notificationsServices.error("Network error");
      });
  };

  return (
    <>
      {stepLoading || loading ? (
        <Spinner />
      ) : (
        <div className="preview-your-ad step-cnt">
          <div className="container">
            <div className="preview-your-ad-content">
              <SliderAds
                sliderRef={sliderRef}
                activeSocial={activeSocial}
                mainImageRef={mainImageRef}
                openPopup={() => setPopupState("flex")}
                googlePreviewRefs={googlePreviewRefs}
              />
              <div className="preview-your-ad-content-column space">
                <NavigationText
                  activeSocial={activeSocial}
                  setActiveSocialView={setActiveSocialView}
                />
                <div className="preview-your-ad-content-column-row">
                  <div className="preview-your-ad-content-column-button">
                    <button className={"button back-button"} onClick={goBack}>
                      Go Back
                    </button>
                    <button className="button" onClick={handlePurchaseAd}>
                      purchase ad
                    </button>
                  </div>
                  <StepsCountColumn />
                </div>
              </div>
            </div>
          </div>
          <AdPopup popupState={popupState} setPopupState={setPopupState}>
            <AdView sliderRef={sliderRef} activeSocial={activeSocial} />
          </AdPopup>
          <PortalCarouselImages setConvertedRefs={setConvertedRefs} />
        </div>
      )}
    </>
  );
};

export default PreviewYourAdView;
