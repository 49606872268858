import React from "react";
import { useSelector } from "react-redux";
import AdView from "./index";
import useSwipe from "../../../hooks/useSwipe";
import {
  selectAdPreviewInfo,
  selectAdType,
  selectAdMediaType,
} from "../../../store/selectors/adSelector";
import { adTypes } from "../../../constants/constants";
import { adMediaTypesEnum } from "../../../constants/enums";

const SliderAds = ({
  sliderRef,
  activeSocial,
  mainImageRef,
  openPopup,
  adId = "",
  isPopup = false,
  googlePreviewRefs,
  userId
}) => {
  const { data } = useSelector(selectAdPreviewInfo);
  const type = useSelector(selectAdType);
  const isSingleImage = isPopup
    ? data?.photos?.length === 1
    : type === adTypes.SINGLE;
  const adMediaType = useSelector(selectAdMediaType);
  const slideCount = adMediaType === adMediaTypesEnum.Facebook || !adMediaType ? 3 : 2;

  const { realIndex, setRealIndex } = useSwipe(sliderRef, {
    disabled: false,
    isScrollable: false,
    freeMode: false,
    childCount: slideCount,
    showFullSlide: true,
  });

  return (
    <>
      <div className="preview-your-ad-content-column">
        <div className="preview-your-ad-swiper-row">
          <div className="preview-your-ad-swipe-block">
            <div ref={sliderRef} className="preview-your-ad-swipe-block-inner">
              <AdView
                disableCarouselSwipe={true}
                sliderRef={sliderRef}
                activeSocial={activeSocial}
                mainImageRef={mainImageRef}
                googlePreviewRefs={googlePreviewRefs}
                openPopup={openPopup}
                adId={adId}
                isPopup={isPopup}
                realIndex={realIndex}
                userId={userId}
              />
            </div>
          </div>
          <i
            className={`p-y-ad-swipe-icon icon-arrow-left left ${
              !realIndex ? "hidden" : ""
            }`}
            onClick={() => setRealIndex(realIndex - 1)}
          />
          <i
            className={`p-y-ad-swipe-icon icon-arrow-right right  ${
              realIndex >= slideCount - 1 ? "hidden" : ""
            }`}
            onClick={() => setRealIndex(realIndex + 1)}
          />
        </div>
      </div>
      <div className={`step-name-container step-${realIndex + 1}`}>
        {(adMediaType === adMediaTypesEnum.Facebook || !adMediaType) ? (
          <>
            <p className="step-name">
              Facebook {isSingleImage ? "Feed" : "Carousel"}
            </p>
            <p className="step-name">Instagram Feed</p>
            <p className="step-name">Facebook/Instagram Story</p>
          </>
        ) : (
          <>
            <p className="step-name">Vertical</p>
            <p className="step-name">Square</p>
          </>
        )}
      </div>
    </>
  );
};

export default SliderAds;
