import { lazy, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { paymentSepsEnum } from "../../constants/enums";

const SelectPaidMediaPackageView = lazy(() =>
  import("./paidMediaPackage/SelectPaidMediaPackageView"));
const PayView = lazy(() => import("./pay/PayView"));
const ConfirmationView = lazy(() => import("./confirmation/ConfirmationView"));

const PaymentSteps = ({ step }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.history.pushState(null, null, location.pathname);

    const disableBackButton = () => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, null, window.location.href);
      };
    };

    disableBackButton();
  }, []);
  const renderPaymentStep = () => {
    switch (step) {
      case paymentSepsEnum.SelectPaidMediaPackage:
        return <SelectPaidMediaPackageView />;
      case paymentSepsEnum.Pay:
        return <PayView />;
      case paymentSepsEnum.Confirmation:
        return <ConfirmationView />;
      default:
        return null;
    }
  };

  return <>{renderPaymentStep()}</>;
};

PaymentSteps.propTypes = {
  step: PropTypes.oneOf(Object.values(paymentSepsEnum)).isRequired,
};

export default PaymentSteps;
