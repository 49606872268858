import get from "lodash/get";
import first from "lodash/first";

import listingActionTypes from "./listingActionTypes";
import { getIsManager } from "../../../helpers/roles";
import {
  facebookPrimaryTextMaxLength,
  listingTabsNamesMapper,
  networkActions,
  promiseStatuses,
} from "../../../constants/constants";
import axios from "../../../api";
import { getStore } from "../../index";
import {
  adMediaTypesEnum,
  goalsEnum,
  paymentSepsEnum,
  promoteStepsEnum,
} from "../../../constants/enums";
import {
  selectAdGoal,
  selectAdMediaType,
  selectFacebookHeadline,
  selectFacebookPrimaryText,
  selectGoogleHeadline,
} from "../../selectors/adSelector";
import getAllListingsTableInfo from "./helpers";
import {
  formatAddressString,
  generateListingUniqueKey,
  getAdConfigByGoal,
} from "../../../helpers/formatters";
import {
  isValidPrimaryText,
  isValidHeadline,
} from "../../../helpers/validations";
import {
  selectIsListingAllLoaded,
  selectAgentListOfListings,
  selectListingImages,
  selectListingPhotos,
  selectPromoteListingStep,
  selectAgentListingsLoading,
} from "../../selectors/listingSelector";
import {
  selectIsAmpAvailable,
  selectIsCreditCardAvailable,
} from "../../selectors/paymentSelector";
// eslint-disable-next-line import/no-cycle
import {
  emptyAdDataAction,
  publishAdAction,
  setAdListingIdAction,
  setAdHousePhotosCarouselAction,
  setAdHouseFbPhotosSingleAction,
  setAdHouseGooglePhotosSingleAction,
  setAdConfigsByGoalAction,
  setAdFacebookHeadlineAction,
  setAdFacebookPrimaryTextAction,
  setAdGoogleHeadlineAction,
  setIsComingSoon,
} from "../ad/adActions";
import {
  emptyPaymentDataAction,
  saveMarketingBudgetsAction,
} from "../payment/paymentActions";
import { notificationsServices } from "../../../helpers/notifications";
import { isValidImage } from "../../../helpers/image";
import { getMaxPhotoCount } from "../../../helpers/functions";
import authenticationService from "../../../services/auth";
import { setManagerReset } from "../manager/managerActions";
import { selectIsManager } from "../../selectors/permissionSelector";
import { setEmptyManagerAd } from "../managerAd/managerAdAction";
import { initialStateAgentListingsFilter } from "../../reducers/listingReducer";

export const setAgentListingLoading = (loading) => (dispatch) =>
  dispatch({
    type: listingActionTypes.GET_AGENT_ALL_LISTINGS_LOADING,
    payload: loading,
  });
const setAnalyticsLoading = (loading) => (dispatch) =>
  dispatch({
    type: listingActionTypes.SET_ANALYTICS_LOADED,
    payload: loading,
  });

export const setAgentListingFilterAction = (filter) => (dispatch) =>
  dispatch({
    type: listingActionTypes.SAVE_AGENT_ALL_LISTINGS_FILTER,
    payload: filter,
  });

export const setStepLoadingAction = (loading) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SET_STEP_LOADING,
    payload: loading,
  });
};

export const setUnderMaintenanceListing =
  (isUnderMaintenance) => (dispatch) => {
    dispatch({
      type: listingActionTypes.SET_UNDER_MAINTENANCE_LISTING,
      payload: isUnderMaintenance,
    });
  };

export const setListingErrorAction = (error) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SAVE_AGENT_ALL_LISTINGS_ERROR,
    payload: error,
  });
};

export const saveListingInfoAction = (listingInfo) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SAVE_LISTING_INFO,
    payload: listingInfo,
  });
};

export const saveListingFbInfoAction = (listingFbInfo) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SAVE_LISTING_FB_INFO,
    payload: listingFbInfo,
  });
};

export const getAgentListingsAction =
  (filter, isScroll = false) =>
  async (dispatch) => {
    try {
      const store = getStore();
      const isListingAllLoaded = selectIsListingAllLoaded(store);
      const agentListingsLoading = selectAgentListingsLoading(store);
      const isManager = selectIsManager(store);
      if (isManager) return;
      if (
        (isListingAllLoaded && isScroll) ||
        (agentListingsLoading && isScroll)
      ) {
        return;
      }

      dispatch(setAgentListingLoading(true));

      const agentAllListings = isScroll ? getStore().listing.agentListings : [];
      const pageNumber = isScroll ? filter.pageNumber + 1 : 0;

      const agentListing = await axios.post(networkActions.GET_AGENT_LISTINGS, {
        [filter.selectedType]: filter.searchText,
      pageNumber,
      });

      const { data } = agentListing;

      if (Array.isArray(data) && Array.isArray(agentAllListings)) {
        if (data.length === 0 && agentAllListings.length > 0) {
          return dispatch({
            type: listingActionTypes.SET_IS_AGENT_ALL_LISTING_LOADED,
            payload: true,
          });
        }

        const listing = [...agentAllListings, ...data];

        dispatch({
          type: listingActionTypes.SAVE_AGENT_ALL_LISTINGS,
          payload: {
            listing,
            agentListingsFilter: {
              ...filter,
              pageNumber,
            },
          },
        });
      }
    } catch (error) {
      if (error.response.status === 446) {
        dispatch(setUnderMaintenanceListing(true));
      } else {
        dispatch(setAgentListingLoading(false));
        notificationsServices.error(error);
      }
    }
  };

const isValidPreviewCopyData = () => {
  const store = getStore();
  const adMediaType = selectAdMediaType(store);

  if (adMediaType === adMediaTypesEnum.Facebook) {
    const facebookHeadline = selectFacebookHeadline(store);
    const facebookPrimaryText = selectFacebookPrimaryText(store);

    if (
      !isValidHeadline(facebookHeadline) ||
      !isValidPrimaryText(facebookPrimaryText, facebookPrimaryTextMaxLength)
    ) {
      return false;
    }
  }

  if (adMediaType === adMediaTypesEnum.Google) {
    const googleHeadline = selectGoogleHeadline(store);

    if (!isValidHeadline(googleHeadline)) {
      return false;
    }
  }

  return true;
};

export const emptyListingInfoAction = () => (dispatch) => {
  dispatch({
    type: listingActionTypes.EMPTY_LISTING_INFO,
  });
};

export const emptyAllListingAction = () => (dispatch) => {
  dispatch({
    type: listingActionTypes.EMPTY_AGENT_ALL_LISTINGS,
  });
};

export const emptyAdAction = () => (dispatch) => {
  window.scrollTo(0, 0);
  dispatch(emptyAllListingAction());
  dispatch(emptyAdDataAction());
  dispatch(emptyListingInfoAction());
  dispatch(getAgentListingsAction(initialStateAgentListingsFilter));
  dispatch(emptyPaymentDataAction());
  dispatch(setStepLoadingAction(false));
  const rootUser = authenticationService.getRootUser();
  const isManager = getIsManager(rootUser.role);
  if (isManager) {
    dispatch(setManagerReset(true));
    dispatch(setEmptyManagerAd());
  }
};

const setDefaultPhotos = async (photos, goal) => {
  const preSelectPhotos = [];
  const newPhotos = [...photos];
  const photosMaxCount = getMaxPhotoCount(goal);

  const iterationCount =
    photosMaxCount > photos.length ? 1 : photos.length - photosMaxCount + 1;

  for (let i = 0; i < iterationCount; i += photosMaxCount) {
    if (preSelectPhotos.length === photosMaxCount) {
      break;
    }

    const promises = photos
      .slice(i, photosMaxCount + i)
      .map((item) => isValidImage(item));

    // eslint-disable-next-line no-await-in-loop
    await Promise.allSettled(promises).then((results) =>
      results.forEach((result) => {
        if (preSelectPhotos.length === photosMaxCount) {
          return;
        }

        if (result.status === promiseStatuses.FULFILLED) {
          return preSelectPhotos.push(result.value);
        }

        if (result.status === promiseStatuses.REJECTED) {
          const index = newPhotos.indexOf(result.reason);
          newPhotos.splice(index, 1);
        }
      }));
  }

  return {
    preSelectPhotos,
    newPhotos,
  };
};

export const setPromoteListingStepAction = (step) => async (dispatch) => {
  if (step === 0) {
    dispatch(emptyAdAction());
  }
  if (step === promoteStepsEnum.ChooseYourMedia) {
    const store = getStore();
    const goal = selectAdGoal(store);
    const photos = selectListingPhotos(store);
    const { preSelectPhotos } = await setDefaultPhotos(photos, goal);
    dispatch(setAdHousePhotosCarouselAction(preSelectPhotos));
  }

  if (
    step === promoteStepsEnum.ChooseListingImages &&
    !isValidPreviewCopyData()
  ) {
    return;
  }

  dispatch({
    type: listingActionTypes.SET_PROMOTE_LISTING_STEP,
    payload: step,
  });
};

export const getListingInfoAction =
  (listingId, externalId) => async (dispatch) => {
    try {
      const store = getStore();
      const adGoalType = selectAdGoal(store);

      dispatch(setStepLoadingAction(true));

      const response = await axios.get(
        `${networkActions.GET_LISTING_INFO}?listingId=${listingId}&externalId=${externalId}`
      );

      const listingAdInfo = get(response, "data.listingAdInfo", {});

      const listingInfoHeadline = get(listingAdInfo, "headline", "") || "";
      const listingInfoPrimaryText =
        get(listingAdInfo, "primaryText", "") || "";
      const photos = get(listingAdInfo, "photos", []);
      const teamInfo = get(response, "data.teamInfo", {});
      const isComingSoon = get(response, "data.isComingSoon", false);
      const listingAdFbInfo = get(response, "data.fbPageInfo", {});
      const agentDesignation = get(response, "data.agentDesignation", "");
      const isAvailableForAd = get(response, "data.isAvelableForAd", true);
      const errorMessage = get(response, "data.errorMessage", "");
      const errorTitle = get(response, "data.errorTitle", "");
      const adConfigsByGoal = get(response, "data.adConfig", []).reduce(
        (ac, data) => {
          const goal = goalsEnum[data.goal];
          ac[goal] = { ...data.budgetInfo, radius: data.radius };
          return ac;
        },
        {}
      );

      dispatch(setAdConfigsByGoalAction(adConfigsByGoal));
      if (listingAdInfo) {
        if (!isAvailableForAd) {
          return dispatch(
            saveListingInfoAction({
              isAvailableForAd,
              errorMessage,
              errorTitle,
            })
          );
        }
        const photosCopy = [...photos];
        const { preSelectPhotos, newPhotos } = await setDefaultPhotos(
          photosCopy,
          adGoalType
        );
        const adConfig = getAdConfigByGoal(adConfigsByGoal[adGoalType]);
        listingAdInfo.photos = newPhotos;
        listingAdInfo.agentDesignation = agentDesignation;
        listingAdInfo.teamInfo = teamInfo;
        // TO DO it is need refactor  and remove  unused data
        dispatch(setIsComingSoon(isComingSoon));
        dispatch(setAdListingIdAction(listingId));
        dispatch(saveListingInfoAction(listingAdInfo));
        dispatch(saveListingFbInfoAction(listingAdFbInfo));
        dispatch(setPromoteListingStepAction(promoteStepsEnum.SelectYourGoal));
        dispatch(saveMarketingBudgetsAction(adConfig));
        dispatch(setAdHouseFbPhotosSingleAction([first(preSelectPhotos)]));
        dispatch(setAdHouseGooglePhotosSingleAction([first(preSelectPhotos)]));
        dispatch(setAdHousePhotosCarouselAction(preSelectPhotos));
        dispatch(setAdFacebookHeadlineAction(listingInfoHeadline));
        dispatch(setAdFacebookPrimaryTextAction(listingInfoPrimaryText));
        dispatch(setAdGoogleHeadlineAction(listingInfoHeadline));
      } else {
        dispatch(setStepLoadingAction(false));
      }
    } catch (error) {
      dispatch(setStepLoadingAction(false));
      notificationsServices.error(error);
    }
  };

export const setListingImagesAction = (payload) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SAVE_LISTING_IMAGES,
    payload,
  });
};

export const setListingImagesLoadingAction = (payload) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SET_LISTING_IMAGES_LOADING,
    payload,
  });
};

export const getListingImagesAction =
  ({ listingId, affiliateId, subAffiliateId }) =>
  async (dispatch) => {
    try {
      const store = getStore();
      const uniqueKey = generateListingUniqueKey({
        listingId,
        affiliateId,
        subAffiliateId,
      });
      const loadedImagesLength = get(
        selectListingImages(store),
        `data.${uniqueKey}`
      );

      if (!Array.isArray(loadedImagesLength)) {
        dispatch(setListingImagesLoadingAction(true));
        const response = await axios.get(
          `${networkActions.GET_LISTING_IMAGES}?listingId=${listingId}&AffiliateId=${affiliateId}&SubAffiliateId=${subAffiliateId}`
        );
        const imagesArray = get(response, "data.images", []);
        if (Array.isArray(imagesArray)) {
          dispatch(setListingImagesAction({ [uniqueKey]: imagesArray }));
        }
      }
    } catch (error) {
      dispatch(setListingImagesLoadingAction(false));
      notificationsServices.error(error);
    }
  };

export const emptyAgentListOfListingsAction = () => (dispatch) => {
  dispatch({
    type: listingActionTypes.EMPTY_AGENT_LIST_OF_LISTINGS,
  });
};

export const setPaymentListingStepAction = (step) => (dispatch) => {
  const store = getStore();
  const isAmpAvailable = selectIsAmpAvailable(store);
  const isCreditCardAvailable = selectIsCreditCardAvailable(store);

  if (
    !isAmpAvailable &&
    !isCreditCardAvailable &&
    step === paymentSepsEnum.Pay
  ) {
    return dispatch(publishAdAction());
  }

  dispatch({
    type: listingActionTypes.SET_PAYMENT_LISTING_STEP,
    payload: step,
  });
};

export const emptyPaymentListingStepAction = () => (dispatch) => {
  dispatch({
    type: listingActionTypes.SET_PAYMENT_LISTING_STEP,
    payload: null,
  });
};

export const setAnalyticsDashboardTabAction = (tab) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SET_ANALYTICS_DASHBOARD_TAB,
    payload: tab,
  });
};

export const setAgentListOfListingsFilterAction = (filter) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SAVE_AGENT_LIST_OF_LISTINGS_FILTER,
    payload: filter,
  });

  const store = getStore();
  const agentListOfListings = selectAgentListOfListings(store);

  dispatch({
    type: listingActionTypes.SAVE_AGENT_LIST_OF_LISTINGS,
    payload: getAllListingsTableInfo({ data: agentListOfListings, ...filter }),
  });
};

export const getAgentListOfListingsAction = (userId) => async (dispatch) => {
  try {
    dispatch(setAnalyticsLoading(true));
    const { data } = await axios.get(
      networkActions.GET_AGENT_LIST_OF_LISTINGS,
      { params: { userId } }
    );
    dispatch(setAnalyticsLoading(false));

    if (Array.isArray(data)) {
      dispatch({
        type: listingActionTypes.SAVE_AGENT_LIST_OF_LISTINGS,
        payload: getAllListingsTableInfo({
          data,
          tab: listingTabsNamesMapper.all,
          search: "",
        }),
      });
    }
  } catch (error) {
    dispatch(setAnalyticsLoading(false));
    notificationsServices.error(error);
  }
};

const setListingAddressLoading = (loading) => (dispatch) =>
  dispatch({
    type: listingActionTypes.SAVE_LISTINGS_ADDRESS_LOADING,
    payload: loading,
  });

const setListingAddressAction = (fullAddress) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SAVE_LISTINGS_ADDRESS,
    payload: fullAddress,
  });
};

const setListingExternalIdAction = (externalId) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SAVE_LISTINGS_EXTERNAL_ID,
    payload: externalId,
  });
};

export const emptyListingAddressAction = () => (dispatch) => {
  dispatch(setListingAddressAction(""));
};

const setCurrentListingActiveStatusAction = (inactive) => (dispatch) => {
  dispatch({
    type: listingActionTypes.SAVE_CURRENT_LISTING_ACTIVE_STATUS,
    payload: inactive,
  });
};

export const getListingAddressAction =
  (listingId, userId) => async (dispatch) => {
    try {
      dispatch(setListingAddressLoading(true));
      dispatch(setListingExternalIdAction(""));
      const { data } = await axios.get(networkActions.GET_LISTING_ADDRESS, {
        params: { listingId, userId },
      });
      dispatch(setCurrentListingActiveStatusAction(data?.inactive));
      dispatch(setListingAddressAction(formatAddressString(data.fullAddress)));
      dispatch(setListingExternalIdAction(data.externalId));
    } catch (error) {
      dispatch(setListingAddressLoading(false));
      dispatch(setListingExternalIdAction(""));
      notificationsServices.error(error);
    }
  };

export const getFairHousingCheckAction = async (text) => {
  const store = getStore();
  const promoteListingStep = selectPromoteListingStep(store);

  if (text === "" || promoteListingStep !== promoteStepsEnum.ReviewCopy) {
    return [];
  }

  try {
    const response = await axios.post(networkActions.FAIR_HOUSING_CHECK, {
      text,
    });
    return get(response, "data.fairWords", []);
  } catch (error) {
    notificationsServices.error(error);
  }
};
