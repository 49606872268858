import { noop } from "lodash";
import React from "react";
import Dialog from "../Dialog/index";

const DelegateAccessPopup = ({
  error,
  value,
  onChange,
  onBlur,
  isDisabled,
  onSubmit,
  onClose = noop,
}) => (
  <Dialog onClose={onClose} className={"delegate-access-popup"}>
    <div className="delegate-popup-container">
      <div className="delegate-popup-close" onClick={onClose}>
        <i className="icon-close " />
      </div>
      <div className="delegate-popup-content">
        <h3 className="delegate-popup-title">Delegate Access</h3>
        <div className={`input-wrapper ${error ? "error" : ""}`}>
          <input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            class="delegate-popup-email-input"
            placeholder="Enter delegate user email address"
          />
          <p className="delegate-popup-error-message">{error}</p>
        </div>
        <p className="delegate-popup-text t-2">
          An email will be sent to this user giving them access to your AdPro
          account. You can remove access at any time.
        </p>
      </div>
      <div className="delegate-popup-buttons">
        <button
          disabled={isDisabled}
          onClick={onSubmit}
          className="delegate-popup-submit-button"
        >
          Delegate
        </button>
      </div>
    </div>
  </Dialog>
);

export default DelegateAccessPopup;
