import React from "react";
import PropTypes from "prop-types";

const Tooltip = ({ children, dark }) => (
    <div className="client-finder-tooltip">
        <i className="icon-info client-finder-tooltip-info-icon" data-html2canvas-ignore/>
        <div className={`client-finder-tooltip-content ${dark ? "dark" : ""}`}>
            {children}
        </div>
    </div>
);

Tooltip.propTypes = {
    children: PropTypes.object,
    dark: PropTypes.bool
};

export default Tooltip;
