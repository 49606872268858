import { managerAdTabs } from "../../constants/enums";
import managerAdAnalyticsTypes from "../actions/managerAdAnalytics/managerAdAnalyticsActionTypes";

const initialState = {
  adDetails: {},
  loading: false,
  hasError: false,
  adInfo: {},
  adInfoLoading: false,
  adInfoError: false,
  allAnalytics: {
    filtersTabsMap: [
      {
        title: "All Ads",
        value: managerAdTabs.ALL_ADS,
        count: 0,
      },
      {
        title: "Promotion Not Started",
        value: managerAdTabs.PROMOTION_NOT_STARTED,
        count: 0,
      },
      {
        title: "In Promotion",
        value: managerAdTabs.IN_PROMOTION,
        count: 0,
      },
      {
        title: "Promotion Ended",
        value: managerAdTabs.PROMOTION_ENDED,
        count: 0,
      },
    ],
  },
};

// eslint-disable-next-line default-param-last
const managerAdAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case managerAdAnalyticsTypes.SET_MANAGER_AD_DETAILS_LOADING:
      return { ...state, loading: action.payload };
    case managerAdAnalyticsTypes.SET_MANAGER_AD_DETAILS:
      return { ...state, adDetails: action.payload };
    case managerAdAnalyticsTypes.SET_MANAGER_AD_ANALYTICS_LOADING:
      return { ...state, loading: action.payload };
    case managerAdAnalyticsTypes.SET_MANAGER_AD_ANALYTICS:
      return { ...state, allAnalytics: action.payload, hasError: false };
    case managerAdAnalyticsTypes.SET_MANAGER_AD_ANALYTICS_ERROR:
      return { ...state, hasError: action.payload };
    case managerAdAnalyticsTypes.SET_MANAGER_AD_INFO:
      return {
        ...state,
        adInfo: action.payload,
        adInfoError: false,
        adInfoLoading: false,
      };
    case managerAdAnalyticsTypes.SET_MANAGER_AD_INFO_LOADING:
      return { ...state, adInfoLoading: action.payload };
    case managerAdAnalyticsTypes.SET_MANAGER_AD_INFO_ERROR:
      return { ...state, adInfoError: action.payload };
    default:
      return state;
  }
};

export default managerAdAnalyticsReducer;
