import React from "react";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { selectListingPhotos } from "../../../store/selectors/listingSelector";
import {
  selectAd,
  selectAdHousePhotosCarousel,
  selectAdFbHousePhotosSingle,
  selectAdGoogleHousePhotosSingle,
  selectAdMediaType,
} from "../../../store/selectors/adSelector";
import {
  setAdTypeAction,
  setHousePhotoAction,
} from "../../../store/actions/ad/adActions";
import Footer from "../../layout/Footer";
import ImagesBottomList from "./ImagesBottomList";
import Notification from "../../shared/Notification";
import { adPhotosMaxCount, adTypes } from "../../../constants/constants";
import { adMediaTypesEnum, goalsEnum } from "../../../constants/enums";

const { SINGLE, CAROUSEL } = adTypes;
const title1 =
  "The “single-image ad” option drives approximately 2x better traffic than carousel ads. We strongly recommend using single-image ads for traffic ads.";
const title2 =
  "The “carousel ad” drives approximately 1.5x more leads. We strongly recommend using multiple images/carousel ads for lead generation ads.";

const ChooseListingImagesView = () => {
  const dispatch = useDispatch();

  const adMediaType = useSelector(selectAdMediaType);
  const adHouseFbPhotosSingle = useSelector(selectAdFbHousePhotosSingle);
  const adHouseGooglePhotosSingle = useSelector(
    selectAdGoogleHousePhotosSingle
  );
  const adHousePhotosCarousel = useSelector(selectAdHousePhotosCarousel);
  const listingPhotos = useSelector(selectListingPhotos);
  const ad = useSelector(selectAd);

  const adType = get(ad, "adType");
  const goal = get(ad, "goal");

  const isCarouselTabActive = adType === adTypes.CAROUSEL;
  const houseSinglePhotos =
    adMediaType === adMediaTypesEnum.Facebook
      ? adHouseFbPhotosSingle
      : adHouseGooglePhotosSingle;
  const adHousePhotos = isCarouselTabActive
    ? adHousePhotosCarousel
    : houseSinglePhotos;
  const isCarouselTabDisabled = listingPhotos?.length < adPhotosMaxCount;
  const isLeads = goal === goalsEnum.LEAD_GENERATION;

  const getTabsProps = (tabName) => ({
    className: `choose-listing-content-tabs-column-tab
            ${tabName === adType ? "active" : ""}
            ${tabName === CAROUSEL && isCarouselTabDisabled ? "disabled" : ""}
            ${tabName === SINGLE && isLeads ? "hidden" : ""}
        `,
    onClick: () => {
      if (tabName !== adType) {
        dispatch(setAdTypeAction(tabName));
      }
    },
  });

  return (
    <div className="choose-listing-images-view-step step-cnt">
      <div className="choose-listing-content">
        <div className="scroll-holder">
          <div className="scrollable-container">
            <div className="container">
              <div className="choose-listing-content-row">
                <div className="choose-listing-content-tabs-column">
                  <div {...getTabsProps(SINGLE)}>Single-image Ad</div>
                  {adMediaType === adMediaTypesEnum.Facebook ? (
                    <div {...getTabsProps(CAROUSEL)}>Carousel Ad</div>
                  ) : null}
                </div>
                {adMediaType === adMediaTypesEnum.Facebook ? (
                  <Notification
                    title={isLeads ? title2 : title1}
                    warning={isCarouselTabActive && !isLeads}
                  />
                ) : null}
              </div>
              <div className="choose-listing-content-inner">
                {Array.isArray(listingPhotos)
                  ? listingPhotos.map((item) => (
                      <div
                        key={item}
                        onClick={() => dispatch(setHousePhotoAction(item))}
                        className={`choose-listing-card ${
                          adHousePhotos?.includes(item) ? "active" : ""
                        }`}
                      >
                        <span className="choose-listing-card-count">
                          {adHousePhotos.indexOf(item) + 1 || ""}
                        </span>
                        <div className="choose-listing-card-img-cnt">
                          <img
                            src={item}
                            alt="house photo"
                            className="choose-listing-card-img"
                          />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <ImagesBottomList />
      </div>
    </div>
  );
};

export default ChooseListingImagesView;
