import React from "react";
import { useSelector } from "react-redux";
import Logo from "../../../assets/images/de.svg";
import DouglasEllimanLogo from "../../../assets/images/douglasEllimanLogoFull.png";
import {
  previewTypeEnum,
  creativeLogoTypesEnum,
} from "../../../constants/enums";
import {
  selectAdPreviewInfo,
  selectConvertedHousePhotos,
  selectGoogleHeadline,
  selectAdLogoType,
} from "../../../store/selectors/adSelector";
import { selectAgentDesignation, selectAgentTeamInfo } from "../../../store/selectors/listingSelector";
import { selectCurrentUser } from "../../../store/selectors/userSelector";
import LogoMaker from "./LogoMaker";

const GooglePreview = ({
  activeSocial,
  openPopup,
  isActive,
  sliderViewType,
}) => {
  const adLogoType = useSelector(selectAdLogoType);
  const isAgentViewMode = adLogoType === creativeLogoTypesEnum.AgentLogo;
  const isTeamViewMode = adLogoType === creativeLogoTypesEnum.CompanyLogo;
  const convertedHousePhotos = useSelector(selectConvertedHousePhotos);
  const agentDesignation = useSelector(selectAgentDesignation);
  const teamInfo = useSelector(selectAgentTeamInfo);
  const currentUser = useSelector(selectCurrentUser);
  const { data, loading } = useSelector(selectAdPreviewInfo);
  const photos = data?.photos || convertedHousePhotos || [];
  const googleHeadline = useSelector(selectGoogleHeadline);
  const isClickable = typeof openPopup === "function";
  const isMobile = window.innerWidth < 758;
  const popupCondition = isClickable && !isMobile;
  const viewType = isMobile ? sliderViewType : activeSocial;

  let adLogo,
    adTitle,
    adDesignation = "";

  if (isAgentViewMode) {
    adLogo = currentUser?.avatarUrl;
    adTitle = `${currentUser?.firstName} ${currentUser?.lastName}`;
    adDesignation = agentDesignation;
  }

  if (isTeamViewMode) {
    adLogo = teamInfo?.teamLogo || Logo;
    adTitle = teamInfo?.teamName;
    adDesignation = currentUser?.teamDesignation;
  }

  return loading ? (
    <div className="loader" data-html2canvas-ignore />
  ) : (
    <div className="preview-your-ad-holder">
      <div
        className={`google-preview your-ad-block ${
          viewType === previewTypeEnum.VERTICAL ? "vertical" : "square"
        } ${isActive ? "show" : "hidden"}`}
        onClick={() => (popupCondition ? openPopup() : null)}
      >
        {/* eslint-disable-next-line max-len */}
        <img src={photos?.[0]?.data} alt="" className="google-ad-img" />
        <div className="google-ad-bottom">
          <div className="google-ad-user-and-title">
            <h3 className="google-ad-title">{googleHeadline}</h3>
            <div className="google-ad-user">
              {isAgentViewMode ? (
                <div className="google-ad-user-logo-and-text">
                  <LogoMaker
                    isGoogle={true}
                    src={adLogo}
                    isTeamViewMode={false}
                  />
                  <div className="google-ad-user-info-column">
                    <p className="google-ad-user-info">{adDesignation}</p>
                    <h3 className="google-ad-user-name">{adTitle}</h3>
                  </div>
                </div>
              ) : (
                <LogoMaker isGoogle={true} src={adLogo} isTeamViewMode={true} />
              )}
            </div>
          </div>
          <div className="google-ad-company-info">
            <div className="google-ad-company-logo">
              <img
                src={DouglasEllimanLogo}
                alt=""
                className="google-ad-company-img"
              />
            </div>
            <button className="google-ad-button">learn more</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GooglePreview;
