import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../layout/Footer";
import { adMediaTypesEnum, promoteStepsEnum } from "../../../constants/enums";
import { setPromoteListingStepAction } from "../../../store/actions/listing/listingActions";
import { selectPromoteListingStep } from "../../../store/selectors/listingSelector";
import {
  selectAdMediaType,
  selectFacebookHeadline,
  selectFacebookHeadlineCheck,
  selectGoogleHeadline,
  selectGoogleHeadlineCheck,
  selectFacebookPrimaryText,
  selectFacebookPrimaryTextCheck,
  selectFacebookValidationLoading,
  selectGoogleValidationLoading,
} from "../../../store/selectors/adSelector";
import StepsCountColumn from "./StepsCountColumn";
import PromoteStepsHeader from "./PromoteStepsHeader";
import { facebookPrimaryTextMaxLength } from "../../../constants/constants";
import {
  isValidPrimaryText,
  isValidHeadline,
} from "../../../helpers/validations";

const mainWrapperSelector = "#root > div";

const StepsContainer = (props) => {
  const promoteListingStep = useSelector(selectPromoteListingStep);
  const adMediaType = useSelector(selectAdMediaType);
  const facebookHeadline = useSelector(selectFacebookHeadline);
  const facebookPrimaryText = useSelector(selectFacebookPrimaryText);
  const facebookHeadlineCheck = useSelector(selectFacebookHeadlineCheck);
  const facebookPrimaryTextCheck = useSelector(selectFacebookPrimaryTextCheck);
  const facebookValidationLoading = useSelector(
    selectFacebookValidationLoading
  );
  const googleHeadline = useSelector(selectGoogleHeadline);
  const googleHeadlineCheck = useSelector(selectGoogleHeadlineCheck);
  const googleValidationLoading = useSelector(selectGoogleValidationLoading);

  const dispatch = useDispatch();

  let disableNextStep;

  if (promoteListingStep === promoteStepsEnum.ChooseYourMedia) {
    disableNextStep = !adMediaType;
  }

  if (promoteListingStep === promoteStepsEnum.ReviewCopy) {
    // eslint-disable-next-line no-use-before-define
    disableNextStep = previewCopyDataHasErrors();
  }

  const checkFacebookValidationLoading =
    promoteListingStep === promoteStepsEnum.SelectYourGoal
      ? false
      : facebookValidationLoading;
  const checkGoogleValidationLoading =
    promoteListingStep === promoteStepsEnum.SelectYourGoal
      ? false
      : googleValidationLoading;

  const isNextArrowDisabled =
    promoteListingStep === promoteStepsEnum.ReviewYourAd ||
    checkFacebookValidationLoading ||
    checkGoogleValidationLoading ||
    disableNextStep;

  function previewCopyDataHasErrors() {
    if (adMediaType === adMediaTypesEnum.Facebook) {
      if (
        !isValidHeadline(facebookHeadline) ||
        !isValidPrimaryText(
          facebookPrimaryText,
          facebookPrimaryTextMaxLength
        ) ||
        facebookHeadlineCheck?.length > 0 ||
        facebookPrimaryTextCheck?.length > 0
      ) {
        return true;
      }
    }

    if (adMediaType === adMediaTypesEnum.Google) {
      if (!isValidHeadline(googleHeadline) || googleHeadlineCheck?.length > 0) {
        return true;
      }
    }

    return false;
  }

  const goBack = () => {
    dispatch(setPromoteListingStepAction(promoteListingStep - 1));
  };

  const navigateToNextStep = () => {
    if (isNextArrowDisabled) {
      return;
    }

    dispatch(setPromoteListingStepAction(promoteListingStep + 1));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const mainWrapper = document.querySelector(mainWrapperSelector);
    if (mainWrapper) {
      mainWrapper.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="steps-wrapper">
      <PromoteStepsHeader />
      <div className="steps-content">{props.children}</div>
      {promoteListingStep !== promoteStepsEnum.ChooseListingImages ? (
        <div className="steps-bottom">
          {promoteListingStep !== promoteStepsEnum.ReviewYourAd ? (
            <div className="next-step-row">
              <div className="container">
                <div className="next-step-row-inner">
                  <div className="next-step-left-column">
                    <div className="next-step-button-block">
                      <button onClick={goBack} className={"button back-button"}>
                        Go Back
                      </button>
                      <button
                        onClick={navigateToNextStep}
                        className={`button ${
                          isNextArrowDisabled ? "disabled" : ""
                        }`}>
                        next step
                      </button>
                    </div>
                  </div>
                  <StepsCountColumn />
                </div>
              </div>
            </div>
          ) : null}
          <Footer />
        </div>
      ) : null}
    </div>
  );
};

StepsContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default StepsContainer;
