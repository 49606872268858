import React from "react";
import SupportPopup from "../support";

const Footer = () => (
  <div className="footer">
    <div className="container">
      <div className="footer-inner">
        <p className="copyright-text">
          <span className="copyright-text-info">
            Powered by Purlin ClientFinder
            <span className="copyright-text-tm">TM</span>
          </span>
        </p>
        <SupportPopup />
      </div>
    </div>
  </div>
);

export default Footer;
