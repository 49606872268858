import React, { useState } from "react";
import PropTypes from "prop-types";

const LogoMaker = ({ src = " ", isTeamViewMode, isGoogle = false }) => {
  const [visibility, setVisibility] = useState(Boolean(src));
  const googleClassName = isTeamViewMode
    ? "google-ad-user-info-logo brightLogo"
    : "google-ad-user-img";
  // eslint-disable-next-line no-nested-ternary
  const avatarWrapperClassName = isGoogle
    ? googleClassName
    : isTeamViewMode
    ? "preview-your-ad-card-head-logo brightLogo"
    : "preview-your-ad-card-head-logo";

  return (
    <div className={avatarWrapperClassName}>
      <img
        src={src}
        alt="avatar"
        onLoad={() => {
          setVisibility(true);
        }}
        onError={() => {
          setVisibility(false);
        }}
        className={`preview-your-ad-logo ${visibility ? "" : "hidden"}`}
      />
    </div>
  );
};

LogoMaker.propTypes = {
  src: PropTypes.string.isRequired,
  isTeamViewMode: PropTypes.bool.isRequired,
  isGoogle: PropTypes.bool,
};

export default LogoMaker;
