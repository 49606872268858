import { get, uniq } from "lodash";
import { networkActions } from "../../../constants/constants";
import managerAdActionTypes from "./managerAdActionTypes";
import { notificationsServices } from "../../../helpers/notifications";
import axios from "../../../api";
import {
  getManagerTargetingRequest,
  uploadManagerVideo,
  managerPublishImage,
  managerPublishVideo,
} from "../../../api/managerAd";
import { setManagerGroupLoading } from "../manager/managerActions";
import {
  getTargetingRegionsKeys,
  transformTargetingRegionData,
} from "../../../helpers/formatters";
import { managerAdSteps } from "../../../constants/enums";

export const getFairHousingCheckAction = async (text) => {
  if (text === "") {
    return [];
  }

  try {
    const response = await axios.post(networkActions.FAIR_HOUSING_CHECK, {
      text,
    });
    return get(response, "data.fairWords", []);
  } catch (error) {
    notificationsServices.error(error);
  }
};

export const setPublishResult = (result) => ({
  type: managerAdActionTypes.SET_PUBLISH_RESULT,
  payload: result,
});

export const setPublishLoading = (loading) => ({
  type: managerAdActionTypes.SET_PUBLISH_LOADING,
  payload: loading,
});
export const setFullName = (fullName) => ({
  type: managerAdActionTypes.SET_CREDIT_FULL_NAME,
  payload: fullName,
});

export const setZipCode = (zipCode) => ({
  type: managerAdActionTypes.SET_CREDIT_ZIP_CODE,
  payload: zipCode,
});

export const setCardNumber = (curdNumber) => ({
  type: managerAdActionTypes.SET_CREDIT_CREDIT_CARD_NUMBER,
  payload: curdNumber,
});

export const setExpiration = (expiration) => ({
  type: managerAdActionTypes.SET_CREDIT_EXPIRATION,
  payload: expiration,
});

export const setManagerAdStepperState = (isOpen) => ({
  type: managerAdActionTypes.SET_STEPPER_STATE,
  payload: isOpen,
});

export const setSelectedBudget = (selectedBudget) => ({
  type: managerAdActionTypes.SET_SELECTED_BUDGET,
  payload: selectedBudget,
});

export const setStepperStep = (step) => ({
  type: managerAdActionTypes.SET_STEPPER_STEP,
  payload: step,
});

export const setManagerAdMediaType = (mediaType) => ({
  type: managerAdActionTypes.SET_MANAGER_MEDIA_TYPE,
  payload: mediaType,
});

export const setManagerAdFeedFile = (file) => ({
  type: managerAdActionTypes.SET_MANAGER_FEED_FILE,
  payload: file,
});

export const setManagerAdStoryFile = (file) => ({
  type: managerAdActionTypes.SET_MANAGER_STORY_FILE,
  payload: file,
});

export const setEmptyManagerAd = () => ({
  type: managerAdActionTypes.SET_MANAGER_AD_EMPTY,
});

export const setAdHeadline = (text) => async (dispatch) => {
  dispatch({
    type: managerAdActionTypes.SET_MANAGER_HEADLINE_TEXT,
    payload: text,
  });

  const violateWords = await getFairHousingCheckAction(text);

  dispatch({
    type: managerAdActionTypes.SET_MANAGER_HEADLINE_CHECK,
    payload: Array.isArray(violateWords) ? violateWords : [],
  });
};

export const setAdPrimaryText = (text) => async (dispatch) => {
  dispatch({
    type: managerAdActionTypes.SET_MANAGER_PRIMARY_TEXT,
    payload: text,
  });

  const violateWords = await getFairHousingCheckAction(text);
  dispatch({
    type: managerAdActionTypes.SET_MANAGER_PRIMARY_CHECK,
    payload: Array.isArray(violateWords) ? violateWords : [],
  });
};

export const setDestinationLink = (destinationLink) => ({
  type: managerAdActionTypes.SET_MANAGER_LINK_TEXT,
  payload: destinationLink,
});

export const setDestinationLinkError = (destinationLinkError) => ({
  type: managerAdActionTypes.SET_MANAGER_LINK_ERROR,
  payload: destinationLinkError,
});

export const setActionButton = (actionButton) => ({
  type: managerAdActionTypes.SET_MANAGER_ACTION_BUTTON,
  payload: actionButton,
});

export const setIsNational = (isNational) => ({
  type: managerAdActionTypes.SET_IS_NATIONAL,
  payload: isNational,
});

export const setIsCorporate = (isCorporate) => ({
  type: managerAdActionTypes.SET_IS_CORPORATE,
  payload: isCorporate,
});

export const setRegionList = (regionList) => ({
  type: managerAdActionTypes.SET_REGION_LIST,
  payload: regionList,
});

export const setBudgetInfo = (budgetInfo) => ({
  type: managerAdActionTypes.SET_BUDGET_INFO,
  payload: budgetInfo,
});
export const setSelectedRegions = (selectedRegions) => ({
  type: managerAdActionTypes.SET_SELECTED_REGIONS,
  payload: selectedRegions,
});

export const setTargetingOptions = (data) => ({
  type: managerAdActionTypes.SET_TARGETING_OPTIONS,
  payload: data,
});

export const openAdStepper = () => async (dispatch) => {
  try {
    dispatch(setManagerGroupLoading(true));
    const managerAdData = await getManagerTargetingRequest();
    const regions = transformTargetingRegionData(
      managerAdData?.data?.targetingRegions
    );
    dispatch(setTargetingOptions({ ...managerAdData?.data, regions }));

    dispatch(setManagerAdStepperState(true));
    dispatch(setManagerGroupLoading(false));
  } catch (e) {
    dispatch(setManagerGroupLoading(false));
  }
};

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const publishManagerVideo = (storyData) => async (dispatch) => {
  try {
    dispatch(setPublishLoading(true));

    const videoFormData = new FormData();
    videoFormData.append("StoryVideo", storyData?.storyFile);
    videoFormData.append("FeedVideo", storyData?.feedFile);
    videoFormData.append("isCorporate", storyData.isNational);
    videoFormData.append("marketingGoal", storyData.marketingGoal);
    if (!storyData.isNational) {
      videoFormData.append("targetedRegion", storyData.stateOptions?.[0]);
    }
    const response = await uploadManagerVideo(videoFormData);
    const { data } = response;
    if (data) {
      const { feedVideoId, feedVideoUrl, storyVideoId, storyVideoUrl } = data;
      const requestData = {
        paymentType: 1,
        payWithCreditCard: {
          cardNumber: storyData?.cardNumber,
          expirationDate: storyData?.expirationDate,
          billingZipCode: storyData?.zipCode,
          billingFirstName: storyData?.firstName,
          billingLastName: storyData?.lastName,
        },
        createAd: {
          creativeLogoType: storyData?.creativeLogoType,
          adSpecs: [
            {
              socialPlatform: storyData?.facebookType,
              headline: storyData?.headline,
              primaryText: storyData?.primary,
              callToActionButton: storyData?.callToActionButton,
            },
          ],
          budgetId: storyData?.budgetId,
          goal: storyData?.goal,
          adType: 3,
          marketingGoal: storyData?.marketingGoal,
          feedVideoId,
          storyVideoId,
          feedVideoUrl,
          storyVideoUrl,
          destinationUrl: storyData?.destinationLink,
          targetingOptions: {
            keys: storyData?.targetingOptionsKeys,
            isCorporate: storyData?.isNational,
          },
          ...(!storyData?.isNational
            ? { targetedRegion: storyData?.stateOptions?.[0] }
            : {}),
        },
      };

      // TO DO  need because fb api is not working
      // correctly and video is  uploading after 1-2 seconds
      await delay(10000);

      const videoResponse = await managerPublishVideo(requestData);

      if (videoResponse?.data?.isSuccess) {
        const { data } = videoResponse;
        const paymentResult = {
          ...data.invoiceInfo,
          adId: data.adId,
        };
        dispatch(setPublishResult(paymentResult));
        dispatch(setPublishLoading(false));
        dispatch(setStepperStep(managerAdSteps.CONFIRM));
      } else {
        notificationsServices.error(videoResponse?.data?.additionalMessage);
      }
    } else {
      notificationsServices.error(response?.data?.additionalMessage);
      dispatch(setPublishLoading(false));
    }
  } catch (e) {
    dispatch(setPublishLoading(false));
  } finally {
    dispatch(setPublishLoading(false));
  }
};

export const publishManagerImage = (requestData) => async (dispatch) => {
  try {
    dispatch(setPublishLoading(true));

    const formData = new FormData();
    formData.append("CreateAd.StoryImage", requestData.storyFile);
    formData.append("CreateAd.FeedImage", requestData.feedFile);
    formData.append("CreateAd.Goal", requestData.goal);
    formData.append("CreateAd.BudgetId", requestData.budgetId);
    formData.append("CreateAd.CreativeLogoType", requestData.creativeLogoType);
    formData.append("CreateAd.MarketingGoal", requestData.marketingGoal);
    // formData.append("CreateAd.AdType", requestData."SingleImage");
    formData.append("CreateAd.DestinationUrl", requestData.destinationLink);
    formData.append("CreateAd.StoryImage", requestData.storyFile);
    formData.append("CreateAd.FeedImage", requestData.feedFile);
    formData.append(
      "CreateAd.AdSpecs[0].socialPlatform",
      requestData.facebookType
    );
    formData.append("CreateAd.AdSpecs[0].headline", requestData.headline);
    formData.append(
      "CreateAd.AdSpecs[0].callToActionButton",
      requestData.callToActionButton
    );
    formData.append("CreateAd.AdSpecs[0].primaryText", requestData.primary);
    formData.append("PaymentType", requestData.paymentType);
    formData.append("PayWithCreditCard.BillingZipCode", requestData.zipCode);
    formData.append("PayWithCreditCard.CardNumber", requestData.cardNumber);
    formData.append(
      "PayWithCreditCard.ExpirationDate",
      requestData.expirationDate
    );
    formData.append(
      "PayWithCreditCard.BillingFirstName",
      requestData.firstName
    );
    formData.append("PayWithCreditCard.BillingLastName", requestData.lastName);
    formData.append(
      "CreateAd.TargetingOptions.IsCorporate",
      requestData.isNational
    );

    if (!requestData.isNational) {
      requestData.targetingOptionsKeys.forEach((key, index) => {
        formData.append(`CreateAd.TargetingOptions.Keys[${index}]`, key);
      });
    }
    if (!requestData.isNational) {
      formData.append("CreateAd.TargetedRegion", requestData.stateOptions?.[0]);
    }
    const response = await managerPublishImage(formData);

    if (response?.data?.isSuccess) {
      const { data } = response;
      const paymentResult = {
        ...data.invoiceInfo,
        adId: data.adId,
      };
      dispatch(setPublishResult(paymentResult));
      dispatch(setPublishLoading(false));
      dispatch(setStepperStep(managerAdSteps.CONFIRM));
    } else {
      notificationsServices.error(response?.data?.additionalMessage);
      dispatch(setPublishLoading(false));
    }
  } catch (error) {
    notificationsServices.error(error);
    dispatch(setPublishLoading(false));
  } finally {
    dispatch(setPublishLoading(false));
  }
};

export const publishManagerAd = () => async (dispatch, getState) => {
  try {
    const store = getState();
    dispatch(setPublishLoading(true));

    const managerAd = store.managerAd;
    const {
      feedFile,
      storyFile,
      headline,
      primary,
      destinationLink,
      actionButton,
      selectedRegions,
      isNational,
      selectedBudget,
      creditFullName,
      zipCode,
      creditCardNumber,
      creditExpirationDate,
    } = managerAd;
    const budgetId = selectedBudget?.id;
    const goal = "LINK_CLICKS";
    const facebookType = "Facebook_Instagram";
    const creativeLogoType = 1;
    const marketingGoal = 2;
    const paymentType = 1; // we have only one payment type for manager ad
    const cardNumber = creditCardNumber.replaceAll(" ", "");
    const expirationDate = creditExpirationDate.replace("/", "");
    const firstName = creditFullName.split(" ")[0];
    const lastName = creditFullName.split(" ")[1];
    const callToActionButton = actionButton?.value;
    const targetingOptionsKeys = getTargetingRegionsKeys(selectedRegions);
    const stateOptions = uniq(selectedRegions?.map((item) => item.state));

    const requestData = {
      feedFile,
      storyFile,
      headline,
      primary,
      destinationLink: destinationLink.toLowerCase(),
      callToActionButton,
      selectedRegions,
      isNational,
      zipCode,
      creativeLogoType,
      targetingOptionsKeys,
      marketingGoal,
      goal,
      facebookType,
      cardNumber,
      paymentType,
      firstName,
      lastName,
      expirationDate,
      budgetId,
      stateOptions,
    };
    const typeName = managerAd?.storyFile.type;
    const [fileType] = typeName.split("/");
    if (fileType === "video") {
      dispatch(publishManagerVideo(requestData));
    } else {
      dispatch(publishManagerImage(requestData));
    }
  } catch (error) {
    notificationsServices.error(error);
  }
};
