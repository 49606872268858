import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { callToActionEnum } from "../../../constants/enums";
import { adCallActions } from "../../../constants/constants";
import { selectAdCallToAction } from "../../../store/selectors/adSelector";
import { setAdCallAction } from "../../../store/actions/ad/adActions";

const CallToActionForm = () => {
  const callAction =
    useSelector(selectAdCallToAction) || callToActionEnum.ShopNow;

  const dispatch = useDispatch();

  return (
    <div className="review-copy-radio-buttons-row regular-radio-buttons">
      <span className="review-copy-input-r-title">
        Choose Your Call-to-Action Button
      </span>
      <form action="" className="review-copy-radio-form">
        {adCallActions.map((item) => (
          <div key={item.name} className="review-copy-radio-buttons-column">
            <input
              id={callToActionEnum[item.name]}
              type="radio"
              checked={callAction === callToActionEnum[item.name]}
              onChange={() =>
                dispatch(setAdCallAction(callToActionEnum[item.name]))
              }
              name="review-copy-radio"
              className="radio-button"
            />
            <label
              htmlFor={callToActionEnum[item.name]}
              className="review-copy-radio-button-label"
            >
              <div className="steps-custom-radio-button" />
              <p className="review-copy-radio-text">{item.title}</p>
            </label>
          </div>
        ))}
      </form>
    </div>
  );
};

export default CallToActionForm;
