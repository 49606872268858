import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import autosize from "autosize";
import { Tooltip2 } from "@blueprintjs/popover2";
import Facebook from "../../../assets/images/fb.svg";
import Instagram from "../../../assets/images/instagram.svg";

import { adMediaTypesEnum } from "../../../constants/enums";
import Google from "../../../assets/images/google.svg";
import {
  setAdFacebookHeadlineAction,
  setAdFacebookPrimaryTextAction,
  setAdGoogleHeadlineAction,
  setAdGooglePrimaryTextAction,
} from "../../../store/actions/ad/adActions";
import {
  isValidPrimaryText,
  isValidHeadline,
} from "../../../helpers/validations";
import {
  facebookHeadlineMaxLength,
  facebookPrimaryTextMaxLength,
  googleHeadlineMaxLength,
  googlePrimaryTextMaxLength,
} from "../../../constants/constants";
import {
  selectFacebookHeadline,
  selectFacebookHeadlineCheck,
  selectFacebookPrimaryText,
  selectFacebookPrimaryTextCheck,
  selectGoogleHeadline,
  selectGoogleHeadlineCheck,
  selectGooglePrimaryText,
  selectIsComingSoon,
} from "../../../store/selectors/adSelector";
import CallToActionForm from "./CallToActionForm";

const ReviewCopyForm = ({ mediaType, callToActionFormVisible }) => {
  const defaultHeadlineSelector =
    mediaType === adMediaTypesEnum.Facebook
      ? selectFacebookHeadline
      : selectGoogleHeadline;
  const defaultPrimaryTextSelector =
    mediaType === adMediaTypesEnum.Facebook
      ? selectFacebookPrimaryText
      : selectGooglePrimaryText;

  const primaryInputRef = useRef(null);
  const headlineInputRef = useRef(null);
  const [headlineValidation, setHeadlineValidation] = useState(true);
  const [primaryTextValidation, setPrimaryTextValidation] = useState(true);

  const listingAdHeadline = useSelector(defaultHeadlineSelector);
  const listingAdPrimaryText = useSelector(defaultPrimaryTextSelector);
  const facebookHeadlineCheck = useSelector(selectFacebookHeadlineCheck);
  const facebookPrimaryTextCheck = useSelector(selectFacebookPrimaryTextCheck);
  const googleHeadlineCheck = useSelector(selectGoogleHeadlineCheck);
  const isComingSoon = useSelector(selectIsComingSoon);

  const headlineValidationMessage = facebookHeadlineCheck.join(", ");
  const primaryTextValidationMessage = facebookPrimaryTextCheck.join(", ");

  // eslint-disable-next-line no-nested-ternary
  const errorHeadlineClassName = headlineValidation
    ? headlineValidationMessage
      ? "error"
      : ""
    : "error";
  // eslint-disable-next-line no-nested-ternary
  const errorPrimaryClassName = primaryTextValidation
    ? primaryTextValidationMessage
      ? "error"
      : ""
    : "error";

  const headlineMaxLength =
    mediaType === adMediaTypesEnum.Facebook
      ? facebookHeadlineMaxLength
      : googleHeadlineMaxLength;

  const primaryTextMaxLength =
    mediaType === adMediaTypesEnum.Facebook
      ? facebookPrimaryTextMaxLength
      : googlePrimaryTextMaxLength;

  const dispatch = useDispatch();

  const onHeadlineChange = (value) => {
    if (mediaType === adMediaTypesEnum.Facebook) {
      return dispatch(setAdFacebookHeadlineAction(value));
    }

    if (mediaType === adMediaTypesEnum.Google) {
      return dispatch(setAdGoogleHeadlineAction(value));
    }
  };

  const onPrimaryTextKeyDown = (e) => {
    if (e.key === "Enter" && !listingAdPrimaryText) {
      e.preventDefault();
    }
  };

  const onPrimaryTextChange = (value) => {
    if (mediaType === adMediaTypesEnum.Facebook) {
      return dispatch(setAdFacebookPrimaryTextAction(value));
    }

    if (mediaType === adMediaTypesEnum.Google) {
      return dispatch(setAdGooglePrimaryTextAction(value));
    }
  };

  const mediaTitleRenderer = () => {
    if (mediaType === adMediaTypesEnum.Facebook) {
      return (
        <div className="review-copy-social-row">
          <div className="review-copy-social-row-column">
            <div className="review-copy-s-img-block">
              <img src={Facebook} alt="" className="review-copy-s-img" />
            </div>
            <span className="review-copy-social-row-name">facebook</span>
          </div>
          <div className="review-copy-social-row-column">
            <div className="review-copy-s-plus">
              <i className="icon-plus" />
            </div>
          </div>
          <div className="review-copy-social-row-column">
            <div className="review-copy-s-img-block">
              <img src={Instagram} alt="" className="review-copy-s-img" />
            </div>
            <span className="review-copy-social-row-name">instagram</span>
          </div>
        </div>
      );
    }

    if (mediaType === adMediaTypesEnum.Google) {
      return (
        <div className="review-copy-social-row">
          <div className="review-copy-social-row-column">
            <div className="review-copy-s-img-block">
              <img src={Google} alt="" className="review-copy-s-img" />
            </div>
            <span className="review-copy-social-row-name">google</span>
          </div>
        </div>
      );
    }
    return null;
  };

  const getInputLabel = (
    primaryTextValidationMessage,
    errorPrimaryClassName,
    field
  ) => {
    if (primaryTextValidationMessage) {
      return `"${primaryTextValidationMessage}" 
            word(s) cannot be used in this sentence.`;
    }

    return `${errorPrimaryClassName ? "Input " : ""}${field}`;
  };

  useEffect(() => {
    autosize(primaryInputRef.current);
  }, [primaryInputRef]);

  useEffect(() => {
    setPrimaryTextValidation(
      facebookPrimaryTextCheck.length > 0 ||
        isValidPrimaryText(listingAdPrimaryText, primaryTextMaxLength)
    );
  }, [facebookPrimaryTextCheck]);

  useEffect(() => {
    const headlineCheck =
      mediaType === adMediaTypesEnum.Facebook
        ? facebookHeadlineCheck
        : googleHeadlineCheck;
    setHeadlineValidation(
      headlineCheck.length > 0 || isValidHeadline(listingAdHeadline)
    );
  }, [facebookHeadlineCheck, googleHeadlineCheck]);

  useEffect(() => {
    if (primaryInputRef.current) {
      const end = listingAdPrimaryText?.length || 0;
      primaryInputRef.current.setSelectionRange(end, end);
      primaryInputRef.current.focus();
    } else {
      headlineInputRef.current.focus();
    }
  }, []);

  return (
    <div className="review-copy-inputs-column">
      {mediaTitleRenderer()}
      {mediaType === adMediaTypesEnum.Facebook ? (
        <div className={`review-copy-input-row ${errorPrimaryClassName}`}>
          <span className="review-copy-input-r-title">
            {getInputLabel(
              primaryTextValidationMessage,
              errorPrimaryClassName,
              "Primary Text"
            )}
          </span>
          <textarea
            ref={primaryInputRef}
            className="custom-editable-text"
            value={listingAdPrimaryText}
            maxLength={primaryTextMaxLength}
            onKeyDown={onPrimaryTextKeyDown}
            onChange={(e) => onPrimaryTextChange(e.target.value)}
          />
          <p className="review-copy-input-r-latter-count">
            {`${listingAdPrimaryText.length}/${primaryTextMaxLength} (max)`}
          </p>
        </div>
      ) : null}
      <div className={`review-copy-input-row ${errorHeadlineClassName}`}>
        <span className="review-copy-input-r-title">
          {getInputLabel(
            headlineValidationMessage,
            errorHeadlineClassName,
            "Headline"
          )}
          {isComingSoon && <Tooltip2
            popoverClassName="headline-tooltip"
            content={
              <div className="headline-tooltip-content">
                MLS mandates live showing date in advertising.
              </div>
            }
            placement="top"
            usePortal={false}
          >
            <i className="icon-info headline-info" />
          </Tooltip2>}
        </span>
        <input
          ref={headlineInputRef}
          disabled={isComingSoon}
          type="text"
          className="review-copy-input"
          value={listingAdHeadline}
          maxLength={headlineMaxLength}
          onChange={(e) => onHeadlineChange(e.target.value)}
        />
        <p className="review-copy-input-r-latter-count">
          {`${listingAdHeadline.length}/${headlineMaxLength} (max)`}
        </p>
      </div>
      {callToActionFormVisible ? <CallToActionForm /> : null}
    </div>
  );
};

ReviewCopyForm.propTypes = {
  mediaType: PropTypes.oneOf(Object.values(adMediaTypesEnum)).isRequired,
  callToActionFormVisible: PropTypes.bool,
};

export default ReviewCopyForm;
